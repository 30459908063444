var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect } from 'react';
import { Button, Dropdown, DropdownItem, AptiveIcon } from '@aptive-env/storybook';
import styles from './index.module.scss';
var Filters = function (_a) {
    var values = _a.values, options = _a.options, onChange = _a.onChange, onDownload = _a.onDownload;
    var _b = useState({}), filterValues = _b[0], setFilterValues = _b[1];
    var _c = useState({}), dropdownsOpen = _c[0], setDropdownsOpen = _c[1];
    var _d = useState({}), selectedFilters = _d[0], setSelectedFilters = _d[1];
    useEffect(function () {
        var tempFilterValues = {};
        var tempDropdownsOpen = {};
        Object.keys(options).forEach(function (key) {
            if (options[key].items) {
                tempFilterValues[key] = '';
                tempDropdownsOpen[key] = false;
            }
        });
        setFilterValues(tempFilterValues);
        setDropdownsOpen(tempDropdownsOpen);
    }, [options]);
    useEffect(function () {
        setFilterValues(__assign(__assign({}, filterValues), values));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values]);
    var handleDropdownToggle = function (key, value) {
        var tempDropdownsOpen = __assign({}, dropdownsOpen);
        Object.keys(tempDropdownsOpen).forEach(function (dropdownKey) {
            tempDropdownsOpen[dropdownKey] = dropdownKey === key ? value !== null && value !== void 0 ? value : !tempDropdownsOpen[dropdownKey] : false;
        });
        setDropdownsOpen(tempDropdownsOpen);
    };
    var onSetFilter = function (key, item, value) {
        var _a;
        var tempFilterValues = __assign({}, filterValues);
        tempFilterValues[key] = tempFilterValues[key] === value ? '' : value !== null && value !== void 0 ? value : item;
        setFilterValues(tempFilterValues);
        setSelectedFilters(__assign(__assign({}, selectedFilters), (_a = {}, _a[key] = item, _a)));
        onChange(tempFilterValues);
    };
    var handleButtonClick = function (key) {
        if (key === 'download') {
            onDownload();
        }
    };
    return (_jsx("div", __assign({ className: "flex items-center gap-4" }, { children: Object.keys(options).map(function (key) {
            var _a;
            return (_jsx("div", __assign({ className: "relative z-10" }, { children: options[key].items
                    ? (_jsxs(_Fragment, { children: [_jsx(Button, __assign({ className: "w-full bg-white border border-gray-200 text-xs !py-1 !px-2 !rounded-2xl ".concat(styles.dropdownButton), icon: "chevronDown", position: "right", variant: "neutral", onClick: function (event) {
                                    event.stopPropagation();
                                    handleDropdownToggle(key);
                                }, isFilled: true, "aria-label": "Dropdown button", "aria-haspopup": "true" }, { children: _jsx("span", __assign({ className: "text-nowrap" }, { children: selectedFilters[key] ? "".concat(options[key].name, ": ").concat(selectedFilters[key]) : options[key].name })) })), _jsx(Dropdown, __assign({ isOpen: dropdownsOpen[key], onClose: function () { return handleDropdownToggle(key, false); }, defaultValue: filterValues[key], className: "w-full right-0 max-h-[350px] overflow-y-auto" }, { children: (_a = options[key].items) === null || _a === void 0 ? void 0 : _a.sort().map(function (item, index) { return (_jsx(DropdownItem, __assign({ selected: filterValues[key] === item, onClick: function () { var _a, _b; return onSetFilter(key, item, (_b = (_a = options[key]) === null || _a === void 0 ? void 0 : _a.abbr) === null || _b === void 0 ? void 0 : _b[index]); } }, { children: item }), item)); }) }))] }))
                    : (_jsxs(Button, __assign({ className: "flex items-center bg-white border border-gray-200 text-xs !py-1 !px-2 !rounded-2xl", variant: "neutral", isFilled: true, onClick: function () { return handleButtonClick(key); } }, { children: [key === 'filters' && (_jsx(AptiveIcon, { className: "w-[12px] h-[12px] fill-[#4B5563] stroke-none mr-1", icon: "adjustments", isFilled: true })), key === 'download' && (_jsx(AptiveIcon, { className: "w-[16px] h-[16px] stroke-[#4B5563] fill-none mr-1", icon: "documentDownload" })), _jsx("span", { children: options[key].name })] }))) }), key));
        }) })));
};
export default Filters;
