var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useRef } from 'react';
import { Button, Loading } from '@aptive-env/storybook';
export var ProductionDemandUploadButton = function (_a) {
    var isUploading = _a.isUploading, onUpload = _a.onUpload;
    var fileInputRef = useRef(null);
    var handleClickUploadButton = function () {
        var _a;
        (_a = fileInputRef.current) === null || _a === void 0 ? void 0 : _a.click();
    };
    var handleSelectFile = function (event) {
        var _a;
        var file = (_a = event.target.files) === null || _a === void 0 ? void 0 : _a[0];
        if (file) {
            onUpload(file);
        }
    };
    return (_jsxs(_Fragment, { children: [_jsx("input", { type: "file", ref: fileInputRef, className: "hidden", accept: ".csv", onChange: handleSelectFile, onClick: function (event) {
                    event.target.value = '';
                } }), _jsx(Button, __assign({ className: "min-w-[312px] h-[40px]", disabled: isUploading, onClick: handleClickUploadButton }, { children: isUploading ? (_jsxs("div", __assign({ className: "flex items-center gap-3" }, { children: [_jsx("span", { children: "Uploading CSV" }), _jsx(Loading, {})] }))) : (_jsx("span", { children: "Upload CSV" })) }))] }));
};
