import Api from '@/api';
import { createRequestSaga } from '@/redux/helpers';
import { call, put, takeLatest } from 'redux-saga/effects';
import {
  requestUsersAsync,
  requestUserGroupsAsync,
  updateUserMdStatusAsync,
  restoreUserAsync,
  deleteUserAsync,
  updateUserSalesChannelsAsync,
} from './actions';
import { addToastsAction } from '@/redux/toasts';
import { mapErrorToastsData } from '@/lib/api';

function* requestUsersSaga({ payload }) {
  const isActive = payload?.filter?.is_active || false;
  const { successCallback, errorCallback, ...data } = payload;

  try {
    const response = yield call(
      Api.getUsers(isActive),
      { ...data },
    );

    yield put(requestUsersAsync.success(response));

    if (successCallback) {
      yield call(successCallback, response);
    }
  } catch (error) {
    yield put(addToastsAction(mapErrorToastsData(error)));

    if (errorCallback) {
      yield call(errorCallback, error);
    }

    throw error;
  }
}

function* requestUserGroupsSaga({ payload }) {
  try {
    const response = yield call(
      Api.getUserGroups(),
      { ...payload },
    );

    yield put(requestUserGroupsAsync.success(response));
  } catch (error) {
    yield put(addToastsAction(mapErrorToastsData(error)));

    throw error;
  }
}

function* requestUpdateMdStatusSaga({ payload }) {
  const { userId } = payload;
  const result = yield call(
    Api.updateMdHolderStatus(userId),
  );

  yield put(updateUserMdStatusAsync.success(result));
  yield call(payload.callback);
}

function* updateUserSalesChannelsSaga({ payload }) {
  const { userId, salesChannelIds, successCallback } = payload;
  yield call(
    Api.updateUserSalesChannels(userId),
    { sales_channel_ids: salesChannelIds }
  );

  yield put(updateUserSalesChannelsAsync.success({ userId, salesChannelIds }));

  if (successCallback) {
    yield call(successCallback);
  }
}

function* restoreUserSaga({ payload }) {
  const { userId } = payload;
  const result = yield call(
    Api.restoreUser(userId),
  );

  yield put(restoreUserAsync.success(result));
  yield call(payload.callback);
}

function* deleteUserSaga({ payload }) {
  const { userId } = payload;
  const result = yield call(
    Api.deleteUser(userId),
  );

  yield put(deleteUserAsync.success(result));
  yield call(payload.callback);
}

export function* usersActionWatcher() {
  yield takeLatest(
    requestUsersAsync.request,
    createRequestSaga(requestUsersSaga, {
      keyNew: 'users',
      errKey: 'users',
      write: false,
    }),
  );
  yield takeLatest(
    requestUserGroupsAsync.request,
    createRequestSaga(requestUserGroupsSaga, {
      keyNew: 'userGroups',
      errKey: 'userGroups',
      write: false,
    }),
  );
  yield takeLatest(
    deleteUserAsync.request.type,
    createRequestSaga(deleteUserSaga, {
      keyNew: 'deleteUser',
      errKey: 'deleteUser',
      write: true,
    }),
  );
  yield takeLatest(
    restoreUserAsync.request.type,
    createRequestSaga(restoreUserSaga, {
      keyNew: 'restoreUser',
      errKey: 'restoreUser',
      write: true,
    }),
  );
  yield takeLatest(
    updateUserMdStatusAsync.request.type,
    createRequestSaga(requestUpdateMdStatusSaga, {
      keyNew: 'updateMdStatus',
      errKey: 'updateMdStatus',
      write: true,
    }),
  );
  yield takeLatest(
    updateUserSalesChannelsAsync.request.type,
    createRequestSaga(updateUserSalesChannelsSaga, {
      keyNew: 'updateUserSalesChannels',
      errKey: 'updateUserSalesChannels',
      write: true,
    }),
  )
}
