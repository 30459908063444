import * as yup from 'yup';

import {
  REQUIRED,
} from '@/lib/validations';

import { salesChannelsConstants } from '@/modules/AdminTools/lib/constants';

const {
  NAME,
  STATUS,
  CREATION_DATE,
} = salesChannelsConstants;

export const salesChannelValidationSchema = yup.object().shape({
  [NAME]: yup
    .string()
    .ensure()
    .required(REQUIRED),
  [STATUS]: yup
    .boolean()
    .required(REQUIRED),
  [CREATION_DATE]: yup
    .string()
    .ensure()
    .nullable(),
});
