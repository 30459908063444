import { planBuilderConstants } from '@/modules/AdminTools/lib/constants';
import SettingSelect from './SettingSelect';

const { PLAN_SALES_CHANNELS, PLAN_SALES_CHANNELS_LABEL } = planBuilderConstants;

const PlanSalesChannels = () => (
  <SettingSelect
    name={PLAN_SALES_CHANNELS}
    label={PLAN_SALES_CHANNELS_LABEL}
    optionName={'sales_channels'}
    isMulti={true}
    required
  />
);

export default PlanSalesChannels;
