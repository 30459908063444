import { useSelector } from 'react-redux';
import { CustomButton } from '@/components';
import Header from '@/modules/AdminTools/components/Header';
import { salesChannelsSelector } from '@/modules/AdminTools/redux/planBuilder/sales-channels';
import PropTypes from 'prop-types';

const SalesChannelsHeader = ({ onCreateSalesChannelModalOpen }) => {
  const salesChannels = useSelector(salesChannelsSelector);
  const hasSalesChannels = salesChannels?.length > 0;

  return (
    <Header title={'Sales Channels'}>
      {hasSalesChannels && (
        <div className="space-x-2">
          <CustomButton color={'green'} onClick={() => onCreateSalesChannelModalOpen()}>Create Sales Channel</CustomButton>
        </div>
      )}
    </Header>
  );
};

SalesChannelsHeader.propTypes = {
  onCreateSalesChannelModalOpen: PropTypes.func,
};

export default SalesChannelsHeader;
