import { Link, useLocation } from 'react-router-dom';
import { abilityConstants, dashboardConstants, userCan } from '@/lib';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import NavigationMenu from './NavigationMenu';
import { housingConstants } from '@/modules/Housing/lib';
import { useFeatureFlag } from 'configcat-react';

const {
  ACCESS_HOUSING_ABILITY,
} = abilityConstants;

const {
  ARO_PLANNING_PAGE_FEATURE_FLAG_NAME,
  LICENSING_MODULE_FEATURE_FLAG_NAME,
} = dashboardConstants;

const {
  APARTMENT_SETUP_FEATURE_FLAG_NAME,
  BED_MANAGEMENT_FEATURE_FLAG_NAME,
} = housingConstants;

const TopNav = ({ user }) => {
  const location = useLocation();
  const { value: isApartmentSetupEnabled } = useFeatureFlag(
    APARTMENT_SETUP_FEATURE_FLAG_NAME,
    false,
  );
  const { value: isBedManagementEnabled } = useFeatureFlag(
    BED_MANAGEMENT_FEATURE_FLAG_NAME,
    false,
  );
  const { value: isAroPlanningPageEnabled } = useFeatureFlag(
    ARO_PLANNING_PAGE_FEATURE_FLAG_NAME,
    false,
  );
  const { value: isLicensingModuleEnabled } = useFeatureFlag(
    LICENSING_MODULE_FEATURE_FLAG_NAME,
    false,
  );

  const isCurrentLocation = (path) => {
    return path === location.pathname;
  };

  const navigationOption = [
    {
      isDisplayed: true,
      name: 'Onboarding',
      path: '/onboarding',
    },
    {
      isDisplayed: true,
      name: 'Sales planning tool',
      path: '/sales-planning',
    },
    {
      isDisplayed: true,
      name: 'Admin Tools',
      path: '/plan-builder',
    },
    {
      isDisplayed: true,
      name: 'Housing',
      path: '/housing/apartment-setup',
    },
    {
      isDisplayed: isAroPlanningPageEnabled,
      name: 'Routes',
      path: '/aro',
    },
    {
      isDisplayed: isLicensingModuleEnabled,
      name: 'Licensing',
      path: '/licensing',
    },
  ];

  const historyNavOptions = [
    {
      isDisplayed: true,
      name: 'Assignment history',
      description: 'Track sales rep assignments to teams over time.',
      path: '#',
    },
    {
      isDisplayed: true,
      name: 'Bed management history',
      description: 'History of sales rep\'s bed assignments in complexes.',
      path: '/housing/beds-history',
    },
    {
      isDisplayed: true,
      name: 'Rent deduction history',
      description: 'Overview of changes in rent deductions for reps.',
      path: '#',
    },
    {
      isDisplayed: true,
      name: 'Rent history',
      description: 'Record of changes in reps\' housing status and rent.',
      path: '#',
    },
    {
      isDisplayed: true,
      name: 'Rep status history',
      description: 'Detailed history of sales reps\' job status changes.',
      path: '#',
    },
    {
      isDisplayed: true,
      name: 'Upfront pay history',
      description: 'Comprehensive history of reps\' upfront payment records.',
      path: '#',
    },
  ];

  const menuItems = [
    ...(isApartmentSetupEnabled ? [{
      ability: ACCESS_HOUSING_ABILITY,
      name: 'Apartment Setup',
      path: '/housing/apartment-setup',
    }] : []),
    {
      ability: ACCESS_HOUSING_ABILITY,
      name: 'Ledger',
      path: '/housing/ledger',
    },
    {
      ability: ACCESS_HOUSING_ABILITY,
      name: 'Ledger Archive',
      path: '/housing/ledger/archived',
    },
    ...(isBedManagementEnabled ? [{
      ability: ACCESS_HOUSING_ABILITY,
      name: 'Bed management',
      path: '/housing/bed-management',
    }] : []),
  ];

  return (
    <nav className="hidden sm:block bg-gray-100 border-b">
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between h-10">
          <div className="flex sm:space-x-8">
            <NavigationMenu
              name="Housing"
              navigationOption={navigationOption}
              isBrand
            />
            {menuItems.map((item) => (userCan(user, item.ability) ? (
              <Link
                to={item.path}
                key={item.name}
                className={classNames(
                  (
                    item.isCurrentLocation
                      ? item.isCurrentLocation()
                      : isCurrentLocation(item.path)
                  )
                    ? 'border-gray-900 text-gray-900 border-b-4'
                    : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-700 border-b-2',
                  'inline-flex items-center px-1 pt-1 text-sm font-medium',
                )}
              >
                {item.name}
              </Link>
            ) : null))}
            <NavigationMenu
              name="History"
              navigationOption={historyNavOptions}
            />
          </div>
        </div>
      </div>
    </nav>
  );
};

TopNav.propTypes = {
  user: PropTypes.object,
};

export default TopNav;
