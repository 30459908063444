import { useCallback, useMemo, useState } from 'react';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { connect, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { i9ImmigrationStatusRadioConfig } from '@/lib';
import { onboardingConstants } from '@/lib/constants';
import { CustomFormElement, DateOfBirth, PostalCode, Loader } from '@/components/common';
import { addFsExcludeClass } from '@/lib/utils';
import { defaultSelectOption } from '@/lib/configs';
import { selectStates } from '@/redux/addresses';
import { requestDocumentViewLinkAsync, requestI9SignLinkAsync } from '@/redux/contracts';
import { repProfileSelector, hrDataSelector } from '@/redux/onboarding';
import { Button, ConfirmationModal } from '@/components';
import { CustomErrorMessage } from '@/components/common/Form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { validationSchema } from './I9InformationValidationSchema';

const {
  I9_FIRST_NAME,
  FIRST_NAME_LABEL,
  I9_LAST_NAME,
  LAST_NAME_LABEL,
  I9_MIDDLE_INITIAL,
  MIDDLE_INITIAL_LABEL,
  I9_STREET_ADDRESS,
  I9_STREET_ADDRESS_LABEL,
  I9_CITY,
  CITY_LABEL,
  CITY_PLACEHOLDER,
  I9_STATE,
  STATE_LABEL,
  I9_ZIP,
  ZIP_LABEL,
  ZIP_PLACEHOLDER,
  I9_BIRTHDATE,
  DATE_OF_BIRTH_LABEL,
  I9_SSN,
  I9_SSN_LABEL,
  SSN_PLACEHOLDER,
  I9_EMAIL,
  I9_EMAIL_LABEL,
  I9_EMAIL_PLACEHOLDER,
  I9_IMMIGRATION_STATUS,
  I9_IMMIGRATION_STATUS_LABEL,
  FEDERAL_DISCLAIMER,
  I9_CLICKED,
  I9_COMPLETED_NAME,
  DOCUMENT_TYPE_I9,
  DOCUMENT_UPLOAD_NEW_BUTTON,
  DOCUMENT_CONTINUE_BUTTON,
  DOCUMENT_UPLOAD_BUTTON,
} = onboardingConstants;

const I9Information = ({
  canEditField,
  states,
  rep,
  hrData,
  userId,
  isAdmin,
  isPersonalWizard,
}) => {
  const { setValue: setHrFormValue, formState: { errors: hrFormErrors } } = useFormContext();

  const methods = useForm({
    defaultValues: {
      [I9_FIRST_NAME]: rep?.firstName,
      [I9_LAST_NAME]: rep?.lastName,
      [I9_EMAIL]: rep?.email,
    },
    mode: 'all',
    reValidateMode: 'onChange',
    resolver: yupResolver(validationSchema),
  });

  const {
    handleSubmit,
    register,
    setValue,
    getValues,
    formState: { errors },
  } = methods;
  const dispatch = useDispatch();

  const onChangeHandler = useCallback((event) => {
    const { name, value } = event.target;

    setValue(name, value, { shouldValidate: true, shouldDirty: true, shouldTouch: true });
  }, [setValue]);

  const addressStates = useMemo(() => {
    const countryStates = states?.[rep.addressCountry] ?? [];

    return [...defaultSelectOption, ...countryStates];
  }, [states, rep]);

  const uploadButtonText = useMemo(() => {
    if (hrData.i9Submitted) {
      return DOCUMENT_UPLOAD_NEW_BUTTON;
    } else if (hrData.i9EnvelopeSent) {
      return DOCUMENT_CONTINUE_BUTTON;
    } else {
      return DOCUMENT_UPLOAD_BUTTON;
    }
  }, [hrData.i9EnvelopeSent, hrData.i9Submitted]);

  const [showForm, setShowForm] = useState(false);
  const [signLoading, setSignLoading] = useState(false);
  const [viewLoading, setViewLoading] = useState(false);
  const [confirmationOpen, setConfirmationOpen] = useState(false);

  const ssnMask = [/\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];

  const onBlurTrimSpace = useCallback((event, name) => {
    onChangeHandler({
      target: {
        name,
        value: event.target.value.trim(),
      },
      type: event.type,
    });
  }, [onChangeHandler]);

  const onViewLinkLoaded = ({ link }) => {
    window.open(link, '_blank')?.focus();
    setViewLoading(false);
  };

  const onViewClick = () => {
    setViewLoading(true);
    dispatch(requestDocumentViewLinkAsync.request({
      documentType: DOCUMENT_TYPE_I9,
      userId: userId,
      callback: onViewLinkLoaded,
    }));
  };

  const acceptConfirmation = () => {
    const body = {
      first_name: getValues(I9_FIRST_NAME),
      last_name: getValues(I9_LAST_NAME),
      address: getValues(I9_STREET_ADDRESS),
      city: getValues(I9_CITY),
      state: getValues(I9_STATE),
      zip: getValues(I9_ZIP),
      email: getValues(I9_EMAIL),
      ssn: getValues(I9_SSN)?.replace(/\D/g, ''),
      dob: getValues(I9_BIRTHDATE),
      immigration_status: getValues(I9_IMMIGRATION_STATUS),
      ...(getValues(I9_MIDDLE_INITIAL) && { middle_name: getValues(I9_MIDDLE_INITIAL) }),
    };

    setSignLoading(true);
    setConfirmationOpen(false);

    dispatch(requestI9SignLinkAsync.request({
      ...body,
      callback: onSignLinkLoaded,
      errorCallback: () => setSignLoading(false),
    }));
  };

  const onSignLinkLoaded = ({ link }) => {
    setHrFormValue(I9_CLICKED, true, { shouldValidate: true });
    setHrFormValue(I9_COMPLETED_NAME, false, { shouldValidate: true });
    window.open(link, '_blank')?.focus();
    setSignLoading(false);
    setShowForm(false);
  };

  const onUploadClick = () => {
    if (!hrData.i9Submitted && hrData.i9EnvelopeSent) {
      setSignLoading(true);
      dispatch(requestI9SignLinkAsync.request({
        documentType: DOCUMENT_TYPE_I9,
        callback: onSignLinkLoaded,
        errorCallback: () => setSignLoading(false),
      }));
    } else {
      setShowForm(true);
    }
  };

  return (
    <>
      <div>
        {hrData.i9Submitted && !hrData.i9Completed && (
          <div className="text-sm text-red-500">
            Pending Admin
          </div>
        )}
      </div>
      {!showForm ? (
        <div className="grid grid-cols-1 mt-2 gap-y-6 gap-x-4 sm:grid-cols-6 justify-center">
          {viewLoading || signLoading ?
            <Loader className="col-span-6" />
            : (
              <div className="grid grid-cols-2 col-span-6 gap-2">
                <Button
                  className="col-span-1"
                  onClick={() => onViewClick()}
                  disabled={!hrData.i9Submitted || !hrData.i9EnvelopeSent || (!isPersonalWizard && !isAdmin)}
                >
                  View I9
                </Button>
                <Button
                  className="col-span-1"
                  onClick={() => onUploadClick()}
                  disabled={!canEditField(DOCUMENT_TYPE_I9) || !isPersonalWizard || (hrData.i9Submitted && !hrData.i9Completed)}
                >
                  {uploadButtonText}
                </Button>
              </div>
            )}
        </div>
      ) : (
        <FormProvider {...methods}>
          <div className="grid grid-cols-1 mt-2 gap-y-6 gap-x-4 sm:grid-cols-6">
            <div className={'col-span-6 text-xl'}>Basic Information</div>
            <CustomFormElement
              colSpan={2}
              id={I9_FIRST_NAME}
              name={I9_FIRST_NAME}
              label={FIRST_NAME_LABEL}
              type="text"
              onChange={onChangeHandler}
              onBlur={(event) => onBlurTrimSpace(event, I9_FIRST_NAME)}
              register={register}
              error={errors?.[I9_FIRST_NAME]}
              required
              className={addFsExcludeClass()}
              disabled={!canEditField(I9_FIRST_NAME)}
            />

            <CustomFormElement
              colSpan={2}
              id={I9_LAST_NAME}
              name={I9_LAST_NAME}
              label={LAST_NAME_LABEL}
              type="text"
              onChange={onChangeHandler}
              onBlur={(event) => onBlurTrimSpace(event, I9_LAST_NAME)}
              register={register}
              error={errors?.[I9_LAST_NAME]}
              required
              className={addFsExcludeClass()}
              disabled={!canEditField(I9_LAST_NAME)}
            />

            <CustomFormElement
              colSpan={2}
              id={I9_MIDDLE_INITIAL}
              name={I9_MIDDLE_INITIAL}
              label={MIDDLE_INITIAL_LABEL}
              type="text"
              onChange={onChangeHandler}
              onBlur={(event) => onBlurTrimSpace(event, I9_MIDDLE_INITIAL)}
              register={register}
              error={errors?.[I9_MIDDLE_INITIAL]}
              className={addFsExcludeClass()}
              disabled={!canEditField(I9_MIDDLE_INITIAL)}
            />

            <CustomFormElement
              colSpan={6}
              id={I9_STREET_ADDRESS}
              name={I9_STREET_ADDRESS}
              label={I9_STREET_ADDRESS_LABEL}
              type="text"
              onChange={onChangeHandler}
              onBlur={(event) => onBlurTrimSpace(event, I9_STREET_ADDRESS)}
              register={register}
              error={errors?.[I9_STREET_ADDRESS]}
              required
              className={addFsExcludeClass()}
              disabled={!canEditField(I9_STREET_ADDRESS)}
            />

            <CustomFormElement
              colSpan={2}
              id={I9_CITY}
              name={I9_CITY}
              label={CITY_LABEL}
              type="text"
              onChange={onChangeHandler}
              onBlur={(event) => onBlurTrimSpace(event, I9_CITY)}
              register={register}
              error={errors?.[I9_CITY]}
              required
              disabled={!canEditField(I9_CITY)}
              placeholder={CITY_PLACEHOLDER}
            />

            <CustomFormElement
              colSpan={2}
              id={I9_STATE}
              name={I9_STATE}
              label={STATE_LABEL}
              type="select"
              selectOptions={addressStates}
              onChange={onChangeHandler}
              register={register}
              error={errors?.[I9_STATE]}
              required
              disabled={!canEditField(I9_STATE)}
            />

            <PostalCode
              colSpan={2}
              id={I9_ZIP}
              name={I9_ZIP}
              label={ZIP_LABEL}
              onChange={onChangeHandler}
              register={register}
              error={errors?.[I9_ZIP]}
              required
              className={addFsExcludeClass()}
              disabled={!canEditField(I9_ZIP)}
              placeholder={ZIP_PLACEHOLDER}
            />

            <DateOfBirth
              colSpan={3}
              label={DATE_OF_BIRTH_LABEL}
              name={I9_BIRTHDATE}
              id={I9_BIRTHDATE}
              required
              onChange={onChangeHandler}
              type="date"
              showYearDropdown
              error={errors?.[I9_BIRTHDATE]}
              register={register}
            />

            <CustomFormElement
              colSpan={3}
              id={I9_SSN}
              name={I9_SSN}
              label={I9_SSN_LABEL}
              type="masked"
              mask={ssnMask}
              onChange={onChangeHandler}
              register={register}
              error={errors?.[I9_SSN]}
              className={addFsExcludeClass()}
              disabled={!canEditField(I9_SSN)}
              placeholder={SSN_PLACEHOLDER}
            />

            <CustomFormElement
              colSpan={3}
              name={I9_EMAIL}
              id={I9_EMAIL}
              label={I9_EMAIL_LABEL}
              onChange={onChangeHandler}
              required
              type="text"
              error={errors?.[I9_EMAIL]}
              register={register}
              className={addFsExcludeClass()}
              placeholder={I9_EMAIL_PLACEHOLDER}
            />

            <div className={'col-span-6 text-xl border-t'} />
            <div className={'col-span-6 text-sm font-bold'}>{FEDERAL_DISCLAIMER}</div>

            <CustomFormElement
              colSpan={6}
              outerClassName=""
              innerClassName="grid grid-cols-1 !space-x-0 !px-0 !py-0"
              panelWrapperClassName="p-4 text-sm border-b"
              type="radio"
              id={I9_IMMIGRATION_STATUS}
              name={I9_IMMIGRATION_STATUS}
              label={I9_IMMIGRATION_STATUS_LABEL}
              register={register}
              radioOptions={i9ImmigrationStatusRadioConfig}
              onChange={onChangeHandler}
              required
              orientation="horizontal"
              disabled={!canEditField(I9_IMMIGRATION_STATUS)}
              error={errors?.[I9_IMMIGRATION_STATUS]}
            />

            {signLoading ?
              <Loader className="col-span-6" />
              :
              (
                <div className={'grid grid-cols-2 col-span-6 gap-4'}>
                  <Button
                    className="col-span-1"
                    onClick={() => setShowForm(false)}
                    disabled={false}
                  >
                    Cancel
                  </Button>

                  <Button
                    className="col-span-1"
                    onClick={handleSubmit(() => setConfirmationOpen(true))}
                    disabled={false}
                  >
                    Fill & Sign
                  </Button>
                  <ConfirmationModal
                    isOpened={confirmationOpen}
                    modalWidth="max-w-[592px] w-full"
                    onCancel={() => setConfirmationOpen(false)}
                    onAction={acceptConfirmation}
                    title="I-9 Completion Deadline"
                    message={<>Per Federal regulations you have {<strong>3 days</strong>} to complete your I-9 or it will be terminated. Please ensure you have proper documentation to complete within the time period.</>}
                    confirmLabel="Acknowledge"
                  />
                </div>
              )
            }
          </div>
        </FormProvider>
      )}
      {hrFormErrors?.i9Clicked?.message && (
        <CustomErrorMessage className="w-full" text={hrFormErrors?.i9Clicked?.message} />
      )}
    </>
  );
};

I9Information.propTypes = {
  userId: PropTypes.number,
  canEditField: PropTypes.func,
  states: PropTypes.object,
  rep: PropTypes.object,
  hrData: PropTypes.object,
  isAdmin: PropTypes.bool,
  isPersonalWizard: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  rep: repProfileSelector(state),
  hrData: hrDataSelector(state),
  states: selectStates(state),
});

export default connect(mapStateToProps)(I9Information);
