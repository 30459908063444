import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { salesChannelsConstants } from '@/modules/AdminTools/lib/constants';
import { salesChannelsSelector, removeSalesChannelAsync, setSelectedSalesChannel } from '@/modules/AdminTools/redux/planBuilder/sales-channels';
import table from './table';
import SearchableDataTable from '@/modules/AdminTools/components/DataTable/SearchableDataTable';
import PropTypes from 'prop-types';

const { REMOVE_CONFIRM, CREATION_DATE, STATUS, NAME, ID } = salesChannelsConstants;

const sortOrders = [ID, NAME, CREATION_DATE, STATUS];

const SalesChannelsTable = ({ setIsSalesChannelModalOpen }) => {
  const dispatch = useDispatch();

  const salesChannels = useSelector(salesChannelsSelector);

  const loading = useSelector((state) => state?.loading);
  const isLoadingSalesChannels = loading?.salesChannels?.isLoading || false;

  const remove = useCallback(
    (id) => {
      if (!window.confirm(REMOVE_CONFIRM)) {
        return;
      }

      dispatch(removeSalesChannelAsync.request({ id }));
    },
    [dispatch],
  );

  const edit = useCallback(
    (id) => {
      dispatch(setSelectedSalesChannel(salesChannels.find((salesChannel) => salesChannel.id === id)));
      setIsSalesChannelModalOpen(true);
    },
    [dispatch, salesChannels, setIsSalesChannelModalOpen],
  );

  const getSearchField = ({ name }) => name;

  const rowMap = useCallback(
    (salesChannel) => ({
      salesChannel,
      remove: () => remove(salesChannel.id),
      edit: () => edit(salesChannel.id),
    }),
    [remove, edit],
  );

  return (
    <SearchableDataTable
      loading={isLoadingSalesChannels}
      data={salesChannels}
      searchPlaceholder={'Search Sales Channels'}
      {...{ rowMap, table, sortOrders, getSearchField }}
    />
  );
};

SalesChannelsTable.propTypes = {
  setIsSalesChannelModalOpen: PropTypes.func,
};

export default SalesChannelsTable;
