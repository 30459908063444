import FilterValueHeader from './FilterValueHeader';
import FilterValueFooter from './FilterValueFooter';
import classNames from 'classnames';
import { Icon } from '@/components/common/Icon';
import propTypes from 'prop-types';
import { useEffect, useState, useRef, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { debounce } from 'lodash-es';
import { Loader } from '@/components';

const FilterInfiniteLoadList = ({
  options,
  radio = false,
  setFilterOptions,
  filterOptions,
  onClickDelete,
  onCancelClick,
  setSelectedOptions,
  selectedOptions,
  filters,
  setFilters,
  selectedFilterField,
  setIsFilterValueOpen,
  isSingleFilter,
  index,
  fetchMoreOptions,
}) => {
  const dispatch = useDispatch();
  const prevFilterOptions = useRef('');
  const [filteredOptions, setFilteredOptions] = useState(options);
  const [isFilterValid, setIsFilterValid] = useState(
    selectedOptions.length !== 0,
  );
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [hasMorePages, setHasMorePages] = useState(true);
  const listRef = useRef();

  useEffect(() => {
    setIsFilterValid(selectedOptions.length !== 0);
  }, [selectedOptions]);

  const combinedOptions = [
    ...selectedOptions.filter(
      (selected) => !filteredOptions.some(
        (filtered) => JSON.stringify({ label: filtered.name, value: filtered.value }) === JSON.stringify(selected),
      ),
    ).map((selected) => ({ name: selected.label, value: selected.value })),
    ...filteredOptions,
  ];

  const isSelected = (filterValue) => {
    return !!selectedOptions.find(
      (el) => JSON.stringify(el) === JSON.stringify(filterValue),
    );
  };

  const onApplyClick = () => {
    if (index === undefined || index === -1) {
      if (isSingleFilter) {
        setFilters([{ type: selectedFilterField, value: selectedOptions }]);
      } else {
        setFilters([
          ...filters,
          { type: selectedFilterField, value: selectedOptions },
        ]);
      }
    } else {
      const newFilters = [...filters];
      newFilters[index].value = selectedOptions;
      setFilters(newFilters);
    }
    setSelectedOptions([]);
    setIsFilterValueOpen(false);
    setFilterOptions('');
  };

  const fetchOptions = useCallback(
    debounce((page, search) => {
      if (!fetchMoreOptions) {
        return;
      }

      setIsLoading(true);

      fetchMoreOptions(page, search, (response) => {
        setIsLoading(false);
        setPage(page);

        const data = response?.data?.map((user) => ({
          value: +user?.id,
          name: user?.attributes?.name,
        })) ?? [];
        let newOptions;

        if (page === 1) {
          newOptions = data;
        } else {
          newOptions = [...filteredOptions, ...data];
        }

        setFilteredOptions(newOptions);

        if (response?.meta?.total <= newOptions?.length) {
          setHasMorePages(false);
        }
      }, () => {
        setIsLoading(false);
        setFilteredOptions([]);
      });
    }, 500),
    [dispatch, fetchMoreOptions, filteredOptions]
  );

  const handleScroll = useCallback(() => {
    if (listRef.current && fetchMoreOptions && hasMorePages) {
      const { scrollTop, scrollHeight, clientHeight } = listRef.current;

      if (scrollTop + clientHeight >= scrollHeight - 5 && !isLoading) {
        fetchOptions(page + 1, filterOptions);
      }
    }
  }, [page, isLoading, fetchOptions, hasMorePages]);

  useEffect(() => {
    if (listRef.current) {
      listRef.current.addEventListener('scroll', handleScroll);

      return () => {
        if (listRef.current) {
          listRef.current.removeEventListener('scroll', handleScroll);
        }
      };
    }
  }, [handleScroll]);

  useEffect(() => {
    if ((prevFilterOptions.current || filterOptions) && prevFilterOptions.current !== filterOptions) {
      prevFilterOptions.current = filterOptions;
      fetchOptions(1, filterOptions);
    }
  }, [filterOptions]);

  const handleOptionClick = (name, value) => {
    const option = { label: name, value: value };
    const isSelected = selectedOptions.some(
      (selected) => JSON.stringify(selected) === JSON.stringify(option)
    );

    if (isSelected) {
      setSelectedOptions(
        selectedOptions.filter(
          (selected) => JSON.stringify(selected) !== JSON.stringify(option)
        )
      );
    } else {
      const selections = radio ? [option] : [...selectedOptions, option];
      setSelectedOptions(selections);
    }
  };

  return (
    <div
      id="dropdown"
      className="absolute top-6 right-0 z-20 w-56 text-base list-none bg-white rounded-lg shadow-md border border-gray-200"
    >
      <FilterValueHeader
        setFilterOptions={setFilterOptions}
        filterOptions={filterOptions}
        onClickDelete={onClickDelete}
      />
      <ul
        ref={listRef}
        className="divide-y max-h-52 overflow-y-auto no-scrollbar"
        aria-labelledby="dropdownButton"
      >
        {combinedOptions &&
          combinedOptions.map(({ name, value }, i) => (
            <li key={i}>
              <div
                className="flex py-2.5 gap-3 cursor-pointer hover:bg-gray-100"
                onClick={() => handleOptionClick(name, value)}
              >
                <div
                  className={classNames(
                    isSelected({ label: name, value: value })
                      ? 'text-white'
                      : 'text-aptiveblue',
                    'inset-y-0 flex items-center pl-4',
                  )}
                >
                  {isSelected({ label: name, value: value }) ? (
                    <Icon
                      icon="checkBoxSelected"
                      className="w-4 h-4 text-aptiveblue inline"
                    />
                  ) : (
                    <Icon
                      icon="checkBoxUnselected"
                      className="w-4 h-4 text-gray-600 inline"
                    />
                  )}
                </div>
                <div className="text-sm text-gray-600 font-['Inter'] font-normal leading-4 text-left">
                  {name}
                </div>
              </div>
            </li>
          ))}
      </ul>
      {isLoading && (
        <Loader className="h-[22px] leading-[26px]" />
      )}
      <FilterValueFooter
        onCancelClick={onCancelClick}
        onApplyClick={onApplyClick}
        isFilterValid={isFilterValid}
      />
    </div>
  );
};

FilterInfiniteLoadList.propTypes = {
  options: propTypes.array,
  radio: propTypes.bool,
  setFilterOptions: propTypes.func,
  filterOptions: propTypes.string,
  onClickDelete: propTypes.func,
  onCancelClick: propTypes.func,
  setSelectedOptions: propTypes.func,
  selectedOptions: propTypes.array,
  filters: propTypes.array,
  setFilters: propTypes.func,
  selectedFilterField: propTypes.object,
  setIsFilterValueOpen: propTypes.func,
  index: propTypes.number,
  isSingleFilter: propTypes.bool,
  fetchMoreOptions: propTypes.func,
};

export default FilterInfiniteLoadList;
