import * as Api from '@/api/api';

const api = process.env.REACT_APP_PLAN_BUILDER_API;

const path = '/api/v1/plan-builder/sales-channels';

export const getSalesChannels = Api.get({ path, api });

export const updateSalesChannel = (id) => Api.patch({ path: `${path}/${id}`, api });

export const createSalesChannel = Api.post({ path, api });

export const removeSalesChannel = (id) => Api.remove({ path: `${path}/${id}`, api });
