import SectionHeader from '@/modules/AdminTools/modules/PlanBuilder/components/SectionHeader';

const UIElements = () => {
  const number = 5;
  const label = 'UI Elements';
  const description = '';

  return <SectionHeader {...{ number, label, description }} />;
};

export default UIElements;
