import { CustomButton } from '@/components';
import PropTypes from 'prop-types';

const EmptySalesChannels = ({ onCreateSalesChannelModalOpen }) => {
  return (
    <div className="bg-white pt-20 pb-56 rounded-lg border border-gray-200">
      <div className="text-center m-auto">
        <div className="font-semibold">{'You don\'t have any sales channels'}</div>
        <div className="mb-6 mt-1 text-gray-600 text-sm">
          In order to create a sales channel, click the button below to get started.
        </div>
        <CustomButton color={'green'} onClick={() => onCreateSalesChannelModalOpen()}>+ Create a sales channel</CustomButton>
      </div>
    </div>
  );
};

EmptySalesChannels.propTypes = {
  onCreateSalesChannelModalOpen: PropTypes.func,
};

export default EmptySalesChannels;
