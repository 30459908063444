import { useCallback, useMemo, useState } from 'react';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { connect, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { w9TaxClassificationRadioConfig, w9LLCSelectOptions } from '@/lib';
import { onboardingConstants, onboardingDataValues } from '@/lib/constants';
import { CustomFormElement, PostalCode, Loader } from '@/components/common';
import { addFsExcludeClass } from '@/lib/utils';
import { defaultSelectOption } from '@/lib/configs';
import { selectStates } from '@/redux/addresses';
import { requestDocumentSignLinkAsync, requestDocumentViewLinkAsync } from '@/redux/contracts';
import { repProfileSelector, hrDataSelector } from '@/redux/onboarding';
import { Button } from '@/components';
import { isAdminSelector } from '@/redux/auth';
import { CustomErrorMessage } from '@/components/common/Form';
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { validationSchema } from './W9InformationValidationSchema';
import classNames from "classnames";

const {
  DOCUMENT_TYPE_W9,
  W9_FULL_NAME,
  W9_FULL_NAME_LABEL,
  W9_BUSINESS_NAME,
  W9_BUSINESS_LABEL,
  W9_BUSINESS_PLACEHOLDER,
  W9_TAX_CLASSIFICATION,
  W9_TAX_CLASSIFICATION_LABEL,
  W9_TAX_CLASSIFICATION_LLC,
  W9_TAX_CLASSIFICATION_LLC_LABEL,
  W9_TAX_CLASSIFICATION_OTHER,
  W9_TAX_CLASSIFICATION_OTHER_LABEL,
  W9_PAYEE_CODE,
  W9_PAYEE_CODE_LABEL,
  W9_PAYEE_CODE_PLACEHOLDER,
  W9_FATCA_CODE,
  W9_FATCA_CODE_LABEL,
  W9_FATCA_CODE_PLACEHOLDER,
  W9_STREET_ADDRESS,
  W9_STREET_ADDRESS_LABEL,
  W9_CITY,
  CITY_LABEL,
  CITY_PLACEHOLDER,
  W9_STATE,
  STATE_LABEL,
  W9_ZIP,
  ZIP_LABEL,
  W9_ZIP_PLACEHOLDER,
  W9_SSN,
  W9_SSN_LABEL,
  SSN_PLACEHOLDER,
  FEDERAL_DISCLAIMER,
  W9_EIN,
  W9_EIN_LABEL,
  W9_EIN_PLACEHOLDER,
  W9_CLICKED,
  W9_COMPLETED_NAME,
  DOCUMENT_IN_REVIEW_TEXT,
  W9_HELP_TEXT,
  DOCUMENT_UPLOAD_NEW_BUTTON,
  DOCUMENT_CONTINUE_BUTTON,
  DOCUMENT_UPLOAD_BUTTON,
  USE_PREVIOUS_W9_NAME,
  USE_PREVIOUS_W9_TEXT,
} = onboardingConstants;

const W9Information = ({
  canEditField,
  onChangeHandler,
  states,
  rep,
  hrData,
  userId,
  isAdmin,
  isPersonalWizard,
  parentRegister,
}) => {
  const { setValue: setHrFormValue, formState: { errors: hrFormErrors } } = useFormContext();

  const methods = useForm({
    mode: 'all',
    reValidateMode: 'onChange',
    resolver: yupResolver(validationSchema),
  });

  const {
    handleSubmit,
    register,
    setValue,
    getValues,
    formState: { errors },
  } = methods;
  const dispatch = useDispatch();

  const onChangeW9Form = useCallback((event) => {
    const { name, value } = event.target;

    setValue(name, value, { shouldValidate: true, shouldDirty: true, shouldTouch: true });
  }, [setValue]);

  const addressStates = useMemo(() => {
    const countryStates = states?.[rep.addressCountry] ?? [];

    return [...defaultSelectOption, ...countryStates];
  }, [states, rep]);

  const uploadButtonText = useMemo(() => {
    if (hrData.w9Submitted) {
      return DOCUMENT_UPLOAD_NEW_BUTTON;
    } else if (hrData.w9EnvelopeSent) {
      return DOCUMENT_CONTINUE_BUTTON;
    } else {
      return DOCUMENT_UPLOAD_BUTTON;
    }
  }, [hrData.w9EnvelopeSent, hrData.w9Submitted]);

  const [showForm, setShowForm] = useState(false);
  const [signLoading, setSignLoading] = useState(false);
  const [viewLoading, setViewLoading] = useState(false);

  const einMask = [/\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];
  const ssnMask = [/\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];

  const onBlurTrimSpace = useCallback((event, name) => {
    onChangeW9Form({
      target: {
        name,
        value: event.target.value.trim(),
      },
      type: event.type,
    });
  }, [onChangeW9Form]);

  const onViewClick = () => {
    setViewLoading(true);
    dispatch(requestDocumentViewLinkAsync.request({
      documentType: DOCUMENT_TYPE_W9,
      userId: userId,
      callback: onViewLinkLoaded,
    }));
  };

  const onViewLinkLoaded = ({ link }) => {
    window.open(link, '_blank')?.focus();
    setViewLoading(false);
  };

  const onSignClick = () => {
    const body = {
      ssn: getValues(W9_SSN),
      full_name: getValues(W9_FULL_NAME),
      business_name: getValues(W9_BUSINESS_NAME),
      tax_classification: getValues(W9_TAX_CLASSIFICATION),
      payee_code: getValues(W9_PAYEE_CODE),
      fatca_code: getValues(W9_FATCA_CODE),
      street_address: getValues(W9_STREET_ADDRESS),
      city_state_zip: `${getValues(W9_CITY)}, ${getValues(W9_STATE)}, ${getValues(W9_ZIP)}`,
    };
    const ein = getValues(W9_EIN)

    if (taxClassification === onboardingDataValues.W9_LLC_VALUE) {
      body.tax_classification_llc_option = getValues(W9_TAX_CLASSIFICATION_LLC);
    }

    if (taxClassification === onboardingDataValues.W9_OTHER_VALUE) {
      body.tax_classification_other_specification = getValues(W9_TAX_CLASSIFICATION_OTHER);
    }

    if (ein) {
      body.ein = ein;
    }

    setSignLoading(true);
    dispatch(requestDocumentSignLinkAsync.request({
      documentType: DOCUMENT_TYPE_W9,
      requestData: body,
      callback: onSignLinkLoaded,
      errorCallback: () => setSignLoading(false)
    }));
  };

  const onUploadClick = () => {
    if (!hrData.w9Submitted && hrData.w9EnvelopeSent) {
      setSignLoading(true);
      dispatch(requestDocumentSignLinkAsync.request({
        documentType: DOCUMENT_TYPE_W9,
        callback: onSignLinkLoaded,
        errorCallback: () => setSignLoading(false)
      }));
    } else {
      setShowForm(true)
    }
  }

  const onSignLinkLoaded = ({ link }) => {
    setHrFormValue(W9_CLICKED, true, { shouldValidate: true });
    setHrFormValue(W9_COMPLETED_NAME, false, { shouldValidate: true });
    window.open(link, '_blank')?.focus();
    setSignLoading(false);
    setShowForm(false);
  };

  const taxClassification = getValues(W9_TAX_CLASSIFICATION);

  return <>
    <div>
      {hrData.w9Submitted && !hrData.w9Completed && (
        <div className="text-sm text-red-500">
          {DOCUMENT_IN_REVIEW_TEXT}
        </div>
      )}
    </div>
    {!showForm ?
      (
        <div className="grid grid-cols-1 mt-2 gap-y-6 gap-x-4 sm:grid-cols-6 justify-center">
          {viewLoading || signLoading ?
            <Loader className="col-span-6"/>
            : (
              <div className="grid grid-cols-2 col-span-6 gap-2">
                <Button
                  className="col-span-1"
                  onClick={() => onViewClick()}
                  disabled={!hrData.w9Submitted || !hrData.w9EnvelopeSent || (!isPersonalWizard && !isAdmin)}
                >
                  View W9
                </Button>
                <Button
                  className="col-span-1"
                  onClick={() => onUploadClick()}
                  disabled={!canEditField(DOCUMENT_TYPE_W9) || !isPersonalWizard}
                >
                  {uploadButtonText}
                </Button>
              </div>
            )}
        </div>
      )
      :
      (
        <FormProvider {...methods}>
          <div className="grid grid-cols-1 mt-2 gap-y-6 gap-x-4 sm:grid-cols-6">
            <div className={'col-span-6 text-xl'}>Basic Information</div>
            <CustomFormElement
              colSpan={6}
              id={W9_FULL_NAME}
              name={W9_FULL_NAME}
              label={W9_FULL_NAME_LABEL}
              type="text"
              onChange={onChangeW9Form}
              onBlur={(event) => onBlurTrimSpace(event, W9_FULL_NAME)}
              register={register}
              error={errors?.[W9_FULL_NAME]}
              required
              className={addFsExcludeClass()}
              disabled={!canEditField(W9_FULL_NAME)}
            />

            <CustomFormElement
              colSpan={6}
              id={W9_BUSINESS_NAME}
              name={W9_BUSINESS_NAME}
              label={W9_BUSINESS_LABEL}
              type="text"
              onChange={onChangeW9Form}
              onBlur={(event) => onBlurTrimSpace(event, W9_BUSINESS_NAME)}
              register={register}
              error={errors?.[W9_BUSINESS_NAME]}
              className={addFsExcludeClass()}
              disabled={!canEditField(W9_BUSINESS_NAME)}
              placeholder={W9_BUSINESS_PLACEHOLDER}
            />

            <CustomFormElement
              colSpan={6}
              outerClassName=""
              innerClassName="grid grid-cols-2 !space-x-0 !px-0 !py-0"
              panelWrapperClassName="col-span-1 last:col-span-2 p-4 text-sm border-b even:border-l last:border-b-0  "
              type="radio"
              id={W9_TAX_CLASSIFICATION}
              name={W9_TAX_CLASSIFICATION}
              label={W9_TAX_CLASSIFICATION_LABEL}
              register={register}
              radioOptions={w9TaxClassificationRadioConfig}
              onChange={onChangeW9Form}
              orientation="horizontal"
              required
              error={errors?.[W9_TAX_CLASSIFICATION]}
              disabled={!canEditField(W9_TAX_CLASSIFICATION)}
            />

            {taxClassification === onboardingDataValues.W9_LLC_VALUE && (
              <CustomFormElement
                colSpan={6}
                id={W9_TAX_CLASSIFICATION_LLC}
                name={W9_TAX_CLASSIFICATION_LLC}
                label={W9_TAX_CLASSIFICATION_LLC_LABEL}
                type="select"
                selectOptions={w9LLCSelectOptions}
                onChange={onChangeW9Form}
                register={register}
                required
                error={errors?.[W9_TAX_CLASSIFICATION_LLC]}
                disabled={!canEditField(W9_TAX_CLASSIFICATION_LLC)}
              />
            )}

            {taxClassification === onboardingDataValues.W9_OTHER_VALUE && (
              <CustomFormElement
                colSpan={6}
                id={W9_TAX_CLASSIFICATION_OTHER}
                name={W9_TAX_CLASSIFICATION_OTHER}
                label={W9_TAX_CLASSIFICATION_OTHER_LABEL}
                type="text"
                onChange={onChangeW9Form}
                onBlur={(event) => onBlurTrimSpace(event, W9_TAX_CLASSIFICATION_OTHER)}
                register={register}
                required
                error={errors?.[W9_TAX_CLASSIFICATION_OTHER]}
                className={addFsExcludeClass()}
                disabled={!canEditField(W9_TAX_CLASSIFICATION_OTHER)}
              />
            )}

            <CustomFormElement
              colSpan={6}
              id={W9_PAYEE_CODE}
              name={W9_PAYEE_CODE}
              label={W9_PAYEE_CODE_LABEL}
              type="text"
              onChange={onChangeW9Form}
              onBlur={(event) => onBlurTrimSpace(event, W9_PAYEE_CODE)}
              register={register}
              error={errors?.[W9_PAYEE_CODE]}
              className={addFsExcludeClass()}
              disabled={!canEditField(W9_PAYEE_CODE)}
              placeholder={W9_PAYEE_CODE_PLACEHOLDER}
            />

            <CustomFormElement
              colSpan={6}
              id={W9_FATCA_CODE}
              name={W9_FATCA_CODE}
              label={W9_FATCA_CODE_LABEL}
              type="text"
              onChange={onChangeW9Form}
              onBlur={(event) => onBlurTrimSpace(event, W9_FATCA_CODE)}
              register={register}
              error={errors?.[W9_FATCA_CODE]}
              className={addFsExcludeClass()}
              disabled={!canEditField(W9_FATCA_CODE)}
              placeholder={W9_FATCA_CODE_PLACEHOLDER}
            />

            <CustomFormElement
              colSpan={6}
              id={W9_STREET_ADDRESS}
              name={W9_STREET_ADDRESS}
              label={W9_STREET_ADDRESS_LABEL}
              type="text"
              onChange={onChangeW9Form}
              onBlur={(event) => onBlurTrimSpace(event, W9_STREET_ADDRESS)}
              register={register}
              error={errors?.[W9_STREET_ADDRESS]}
              required
              className={addFsExcludeClass()}
              disabled={!canEditField(W9_STREET_ADDRESS)}
            />

            <CustomFormElement
              colSpan={2}
              id={W9_CITY}
              name={W9_CITY}
              label={CITY_LABEL}
              type="text"
              onChange={onChangeW9Form}
              onBlur={(event) => onBlurTrimSpace(event, W9_CITY)}
              register={register}
              error={errors?.[W9_CITY]}
              required
              disabled={!canEditField(W9_CITY)}
              placeholder={CITY_PLACEHOLDER}
            />

            <CustomFormElement
              colSpan={2}
              id={W9_STATE}
              name={W9_STATE}
              label={STATE_LABEL}
              type="select"
              selectOptions={addressStates}
              onChange={onChangeW9Form}
              register={register}
              error={errors?.[W9_STATE]}
              required
              disabled={!canEditField(W9_STATE)}
            />

            <PostalCode
              colSpan={2}
              id={W9_ZIP}
              name={W9_ZIP}
              label={ZIP_LABEL}
              onChange={onChangeW9Form}
              register={register}
              error={errors?.[W9_ZIP]}
              required
              className={addFsExcludeClass()}
              disabled={!canEditField(W9_ZIP)}
              placeholder={W9_ZIP_PLACEHOLDER}
            />

            <div className={'col-span-6 text-xl border-t pt-4'}>Tax Identification Number (TIN)</div>
            <div className={'col-span-6 text-xs'}>{FEDERAL_DISCLAIMER}</div>

            <div className={'col-span-6 grid grid-cols-7 items-center'}>
              <CustomFormElement
                colSpan={3}
                id={W9_SSN}
                name={W9_SSN}
                label={W9_SSN_LABEL}
                type="masked"
                mask={ssnMask}
                onChange={onChangeW9Form}
                register={register}
                error={errors?.[W9_SSN]}
                className={addFsExcludeClass()}
                disabled={!canEditField(W9_SSN)}
                placeholder={SSN_PLACEHOLDER}
              />

              <div className={'col-span-1 text-sm text-center'}>or</div>

              <CustomFormElement
                colSpan={3}
                id={W9_EIN}
                name={W9_EIN}
                label={W9_EIN_LABEL}
                type="masked"
                mask={einMask}
                onChange={onChangeW9Form}
                register={register}
                error={errors?.[W9_EIN]}
                className={addFsExcludeClass()}
                disabled={!canEditField(W9_EIN)}
                placeholder={W9_EIN_PLACEHOLDER}
              />
            </div>

            {signLoading ?
              <Loader className="col-span-6"/>
              :
              (
                <div className={'grid grid-cols-2 col-span-6 gap-4'}>
                  <Button
                    className="col-span-1"
                    onClick={() => setShowForm(false)}
                    disabled={false}
                  >
                    Cancel
                  </Button>

                  <Button
                    className="col-span-1"
                    onClick={handleSubmit(() => onSignClick())}
                    disabled={false}
                  >
                    Fill & Sign
                  </Button>
                </div>
              )
            }
          </div>
        </FormProvider>
      )
    }
    {hrData.previousW9Exists && !hrData.w9Submitted && (
      <CustomFormElement
        label={USE_PREVIOUS_W9_TEXT}
        type="checkbox"
        elementWrapperClassName="shadow-none"
        formElementWrapperClassName="mt-2 shadow-none"
        className={classNames(
          "w-5 h-5 text-indigo-600 border-gray-300 rounded focus:ring-aptivegreen",
          {
            'text-gray-300': !canEditField(USE_PREVIOUS_W9_NAME) || !isPersonalWizard,
          },
        )}
        id={USE_PREVIOUS_W9_NAME}
        register={parentRegister}
        name={USE_PREVIOUS_W9_NAME}
        orientation="vertical"
        panelWrapperClassName="justify-items-end"
        error={errors?.[USE_PREVIOUS_W9_NAME]}
        onChange={onChangeHandler}
        disabled={!canEditField(USE_PREVIOUS_W9_NAME) || !isPersonalWizard}
      />
    )}
    <div className="text-sm text-gray-500 italic mt-2">
      {hrData.w9EnvelopeSent && !hrData.w9Submitted ? W9_HELP_TEXT : null}
    </div>
    {hrFormErrors?.w9Clicked?.message && (
      <CustomErrorMessage className="w-full" text={hrFormErrors?.w9Clicked?.message}/>
    )}
  </>
};

W9Information.propTypes = {
  userId: PropTypes.number,
  canEditField: PropTypes.func,
  onChangeHandler: PropTypes.func,
  states: PropTypes.object,
  rep: PropTypes.object,
  hrData: PropTypes.object,
  isAdmin: PropTypes.bool,
  isPersonalWizard: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  rep: repProfileSelector(state),
  hrData: hrDataSelector(state),
  states: selectStates(state),
  isAdmin: isAdminSelector(state),
});

export default connect(mapStateToProps)(W9Information);
