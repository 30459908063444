import { createAction, createAsyncAction } from '@/redux/root';

import { nameSpace } from '../nameSpace';

export const salesChannelsNameSpace = `${nameSpace}/sales_channels`;

export const requestSalesChannelsAsync = createAsyncAction(
  `${salesChannelsNameSpace}/REQUEST_SALES_CHANNELS`,
);

export const updateSalesChannelAsync = createAsyncAction(
  `${salesChannelsNameSpace}/UPDATE_SALES_CHANNEL`,
);

export const createSalesChannelAsync = createAsyncAction(
  `${salesChannelsNameSpace}/CREATE_SALES_CHANNEL`,
);

export const removeSalesChannelAsync = createAsyncAction(
  `${salesChannelsNameSpace}/REMOVE_SALES_CHANNEL`,
);

export const setSelectedSalesChannel = createAction(
  `${nameSpace}/SET_SELECTED_SALES_CHANNEL`,
);

export const resetSelectedSalesChannel = createAction(
  `${nameSpace}/RESET_SELECTED_SALES_CHANNEL`,
);
