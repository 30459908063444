import { Disclosure } from '@headlessui/react';
import { DocumentIcon, ExclamationIcon } from '@heroicons/react/outline';
import PropTypes from 'prop-types';
import { useCallback, useState, useMemo } from 'react';
import { CustomFormElement, TooltipText, WhiteButton } from '@/components/common';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { AddendumValidationSchema } from './AddendumValidationSchema';
import { dashboardConstants } from '@/lib';
import { addFsExcludeClass, mergeClassName } from '@/lib/utils';

const NewAgreementListRow = ({
  addendumOpened,
  onToggleAddendum,
  agreement,
  onToggleSelect,
  selected,
  addendum,
  md,
  onChangeMd,
}) => {
  const [addendumText, setAddendumText] = useState(addendum);

  const {
    ADDENDUM_NAME,
    ADDENDUM_LABEL,
  } = dashboardConstants;

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {},
    mode: 'all',
    reValidateMode: 'onChange',
    resolver: yupResolver(AddendumValidationSchema),
  });

  const onToggleAgreement = () => {
    setAddendumText('');
    reset({});
    onToggleSelect({ agreement, selected });
  };

  const onChangeAddendumText = (event) => {
    setAddendumText(event.target.value);
  };

  const onChangeMdText = (event) => {
    onChangeMd({ agreement, md: event.target.value });
  };

  const handleSubmitForm = useCallback(() => {
    onToggleAddendum({ agreement, addendum: addendumText });
  }, [addendumOpened, addendumText]);

  const availabilityConditionsTip = useMemo(() => {
    const {
      is_available,
      availability_conditions: {
        type,
        requirements,
      },
    } = agreement;

    // No tip required if the agreement is available
    if (is_available) {
      return;
    }

    // Agreement is not available, but failed to provide any conditions, so return a generic message
    if (!requirements.length) {
      return 'Currently unavailable. Please contact a system administrator for details.';
    }

    // If there is only one requirement, return it as a single message
    if (requirements.length === 1) {
      return requirements[0];
    }

    // Otherwise, return a list of requirements
    const message = [];

    switch (type) {
      case 'anyOf':
        message.push('<p><strong>At least one of the following must be completed:</strong></p>');
        break;

      default:
        message.push('<p><strong>All the following must be completed:</strong></p>');
    }

    message.push(`<ul class="list-disc list-inside"><li>${requirements.join('</li><li>')}</li></ul>`);

    return message.join('');
  }, [agreement]);

  return (
    <li
      className={mergeClassName(
        'bg-white shadow overflow-hidden rounded-md px-1 py-1',
        {
          'hidden': addendumOpened && addendumOpened !== agreement.id,
          'bg-gray-100': !agreement.is_available,
        },
      )}
    >
      <Disclosure>
        <form noValidate onSubmit={handleSubmit(handleSubmitForm)}>
          <div className="px-1 py-1 flex items-center sm:px-2">
            <div className="min-w-0 flex-1 sm:flex sm:items-center sm:justify-start">
              <DocumentIcon
                className={mergeClassName('h-6 w-6 shrink-0 text-aptivegreen', { 'text-gray-400': !agreement.is_available })}
                aria-hidden="true"
              />
              <div className="ml-4 overflow-hidden text-sm">
                <p className={mergeClassName('font-medium text-gray-800 truncate', { 'text-gray-400': !agreement.is_available })}>
                  {agreement.template_name}
                </p>
              </div>
            </div>
            <div className="ml-5 shrink-0">
              {!agreement.is_available ? (
                <TooltipText
                  id={`agreement-${agreement.id}`}
                  text={(
                    <ExclamationIcon
                      className="h-6 w-6 shrink-0 text-yellow-500"
                      aria-hidden="true"
                    />
                  )}
                  message={availabilityConditionsTip}
                  allowHtml={true}
                />
              ) : (
                <>
                  {agreement.is_addendum || agreement.is_MD ? (
                    <>
                      {agreement.is_addendum && selected ? (
                        <div className="inline-block pr-2">
                          {agreement.is_sent
                            ? (
                              <p className="font-light text-xs text-gray-400 truncate">
                                Sent
                              </p>
                            )
                            : (
                              <WhiteButton
                                selected={selected}
                                text={
                                  addendumOpened === agreement.id ? 'Save' : 'Edit'
                                }
                              />
                            )
                          }
                        </div>
                      ) : null}
                      <div onClick={onToggleAgreement} className="inline-block">
                        {agreement.is_sent
                          ? (
                            <p className="font-light text-xs text-gray-400 truncate">
                              Sent
                            </p>
                          )
                          : (
                            <WhiteButton
                              selected={selected}
                              disclosure={true}
                              text={selected ? 'Selected' : 'Select'}
                            />
                          )
                        }
                      </div>
                    </>
                  ) : (
                    <>
                      {agreement.is_sent
                        ? (
                          <p className="font-light text-xs text-gray-400 truncate">
                            Sent
                          </p>
                        )
                        : (
                          <WhiteButton
                            selected={selected}
                            text={selected ? 'Selected' : 'Select'}
                            onClick={onToggleAgreement}
                          />
                        )
                      }
                    </>
                  )}
                </>
              )}
            </div>
          </div>
          {((agreement.is_addendum && addendumOpened === agreement.id) || agreement.is_MD) && selected ? (
            <Disclosure.Panel className="border-t h-68 px-2 py-2" static>
              {agreement.is_MD ? (
                <>
                  <label
                    htmlFor={`md${agreement.id}`}
                    className="block text-xs font-medium text-gray-700"
                  >
                    MD %
                  </label>
                  <input
                    type="text"
                    id={`md${agreement.id}`}
                    className="max-w-lg shadow-sm block w-full focus:ring-aptivegreen focus:border-aptivegreen sm:text-sm border border-gray-100 rounded-md px-2"
                    onChange={onChangeMdText}
                    value={md || ''}
                  />
                </>
              ) : null}
              {agreement.is_addendum ? (
                <CustomFormElement
                  id={ADDENDUM_NAME + agreement.id}
                  name={ADDENDUM_NAME}
                  label={ADDENDUM_LABEL}
                  type="textArea"
                  formValue={addendumText || ''}
                  onChange={onChangeAddendumText}
                  error={errors?.addendum}
                  required
                  rows={10}
                  register={register}
                  className={addFsExcludeClass()}
                />
              ) : null}
            </Disclosure.Panel>
          ) : null}
        </form>
      </Disclosure>
    </li>
  );
};

NewAgreementListRow.propTypes = {
  addendumOpened: PropTypes.string,
  onToggleAddendum: PropTypes.func,
  agreement: PropTypes.shape({
    id: PropTypes.string,
    template_name: PropTypes.string,
    is_available: PropTypes.bool,
    availability_conditions: PropTypes.shape({
      type: PropTypes.oneOf(['allOf', 'anyOf']),
      requirements: PropTypes.arrayOf(PropTypes.string),
    }),
    is_sent: PropTypes.bool,
    is_addendum: PropTypes.bool,
    is_MD: PropTypes.bool,
  }),
  onToggleSelect: PropTypes.func,
  selected: PropTypes.bool,
  addendum: PropTypes.string,
  md: PropTypes.string,
  onChangeMd: PropTypes.func,
};

export default NewAgreementListRow;
