import { useCallback, useState } from 'react';
import { CustomButton } from '@/components';
import SalesChannelModalFooter from './SalesChannelModalFooter';
import SalesChannelModalHeader from './SalesChannelModalHeader';
import SalesChannelInfo from './SalesChannelInfo';
import { salesChannelValidationSchema } from './SalesChannelValidationSchema';
import { connect } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import {
  createSalesChannelAsync,
  updateSalesChannelAsync,
  salesChannelSelector,
} from '@/modules/AdminTools/redux/planBuilder/sales-channels';
import PropTypes from 'prop-types';
import { salesChannelsConstants } from '@/modules/AdminTools/lib/constants';

const {
  NAME,
  STATUS,
} = salesChannelsConstants;

const SalesChannelForm = ({
  onClose,
  salesChannel,
  createSalesChannel,
  updateSalesChannel,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const methods = useForm({
    defaultValues: {
      [NAME]: salesChannel[NAME],
      [STATUS]: salesChannel[STATUS] ?? false,
    },
    mode: 'all',
    reValidateMode: 'onChange',
    resolver: yupResolver(salesChannelValidationSchema),
  });

  const {
    handleSubmit,
    formState: { isValid, isDirty },
    setValue,
    getValues,
  } = methods;

  const onSubmitSuccess = useCallback(() => {
    setIsLoading(false);
    onClose();
  }, [setIsLoading, onClose]);

  const onSubmitFailure = useCallback(() => {
    setIsLoading(false);
  }, [setIsLoading]);

  const handleSubmitForm = useCallback(() => {
    setIsLoading(true);

    if (salesChannel.id) {
      updateSalesChannel({
        id: salesChannel.id,
        data: {
          ...getValues(),
        },
        successCallback: onSubmitSuccess,
        failureCallback: onSubmitFailure,
      });
    } else {
      createSalesChannel({
        data: {
          ...getValues(),
        },
        successCallback: onSubmitSuccess,
        failureCallback: onSubmitFailure,
      });
    }
  }, [
    setIsLoading,
    updateSalesChannel,
    createSalesChannel,
    onSubmitSuccess,
    onSubmitFailure,
    getValues,
    salesChannel,
  ]);

  const handleChange = useCallback((event) => {
    const { name, value, type, checked } = event.target;

    setValue(name, type === 'checkbox' ? checked : value, { shouldValidate: true, shouldDirty: true });
  }, [setValue]);

  return (
    <div className="w-[600px]">
      <FormProvider {...methods}>
        <form noValidate onSubmit={handleSubmit(handleSubmitForm)}>
          <SalesChannelModalHeader>
            <div className="flex justify-start">
              <div className="flex items-center">
                {salesChannel.id ? (
                  <h1 className="font-semibold text-lg">
                    Sales channel: #
                    {salesChannel.id}
                  </h1>
                ) : (
                  <h1 className="font-semibold text-lg">
                    Add a new Sales Channel
                  </h1>
                )}
              </div>
            </div>
          </SalesChannelModalHeader>
          <div className="flex flex-wrap overflow-hidden">
            <div className="p-6 w-full sm:h-[200px] overflow-hidden sm:overflow-y-auto">
              <SalesChannelInfo
                onChangeHandler={handleChange}
              />
            </div>
          </div>
          <SalesChannelModalFooter>
            <div className="flex">
              <div className="ml-auto">
                <CustomButton
                  color="white"
                  onClick={onClose}
                  className={'mr-4 text-gray-600 hover:bg-gray-100'}
                >
                  Cancel
                </CustomButton>
                <CustomButton
                  type="submit"
                  color="green"
                  disabled={!isValid || !isDirty || isLoading}
                >
                  Submit
                </CustomButton>
              </div>
            </div>
          </SalesChannelModalFooter>
        </form>
      </FormProvider>
    </div>
  );
};

SalesChannelForm.propTypes = {
  salesChannel: PropTypes.object,
  createSalesChannel: PropTypes.func,
  updateSalesChannel: PropTypes.func,
  onClose: PropTypes.func,
};

const mapStateToProps = (state) => ({
  salesChannel: salesChannelSelector(state),
});

const mapDispatchToProps = {
  createSalesChannel: createSalesChannelAsync.request,
  updateSalesChannel: updateSalesChannelAsync.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(SalesChannelForm);
