var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState, useEffect, useRef, useCallback } from 'react';
import { fetchPlanningWeeklyShifts } from 'services/planning';
import { format, startOfToday, startOfWeek } from 'date-fns';
import { Card } from '@aptive-env/storybook';
import { AppContext } from 'pages/PlanningView';
import { PlanningOfficeSelect } from 'components/Planning/PlanningOfficeSelect';
import { PlanningDateSelect } from 'components/Planning/PlanningDateSelect';
import { PlanningSummarySection } from 'components/Planning/PlanningSummarySection';
import { PlanningFooterSection } from 'components/Planning/PlanningFooterSection';
import { PlanningCalendar } from 'components/Planning/PlanningCalendar';
import ProfileDrawerModal from 'components/Planning/ProfileDrawerModal';
import { fetchRegions } from 'services/region';
import NoOffice from 'components/NoOffice';
import LoadingGif from 'assets/image/loading.gif';
import { useToast } from 'hooks/useToast';
import { getErrorMessage } from 'utils/error';
var TimelineView = function (_a) {
    var _b, _c, _d;
    var onClickViewProductionDemand = _a.onClickViewProductionDemand;
    var setToast = useToast().setToast;
    var _e = useState(true), isLoading = _e[0], setIsLoading = _e[1];
    var _f = useState(true), isUpdated = _f[0], setIsUpdated = _f[1];
    var _g = useState(), data = _g[0], setData = _g[1];
    var _h = useState([]), regions = _h[0], setRegions = _h[1];
    var _j = useState(), office = _j[0], setOffice = _j[1];
    var _k = useState(startOfWeek(startOfToday(), { weekStartsOn: 0 })), startDate = _k[0], setStartDate = _k[1];
    var _l = useState(false), noOfficeModalOpen = _l[0], setNoOfficeModalOpen = _l[1];
    var fetchPlanningWeeklyShiftsControllerRef = useRef();
    var _m = useState(), selectedEmployee = _m[0], setSelectedEmployee = _m[1];
    var _o = useState(false), isProfileDrawerOpen = _o[0], setIsProfileDrawerOpen = _o[1];
    useEffect(function () {
        fetchRegions()
            .then(function (regions) {
            setRegions(regions);
            if (regions.length > 0) {
                setOffice(regions[0].offices[0]);
            }
            else {
                setNoOfficeModalOpen(true);
            }
        })
            .catch(function (error) {
            setToast({
                status: 'danger',
                message: getErrorMessage(error),
            });
            setIsLoading(false);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    var fetchPlanningShifts = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            setIsLoading(true);
            if (fetchPlanningWeeklyShiftsControllerRef.current) {
                fetchPlanningWeeklyShiftsControllerRef.current.abort();
            }
            fetchPlanningWeeklyShiftsControllerRef.current = new AbortController();
            fetchPlanningWeeklyShifts({
                officeId: office.id,
                startDate: format(startDate, 'yyyy-MM-dd'),
            }, fetchPlanningWeeklyShiftsControllerRef.current.signal)
                .then(function (response) {
                if (response._metadata.success && response.result) {
                    setData(response.result);
                    setIsLoading(false);
                }
                else {
                    console.error('Got unexpected response from fetching planning weekly shifts');
                }
            })
                .catch(function (error) {
                if (error.code !== 'ERR_CANCELED') {
                    setToast({
                        status: 'danger',
                        message: getErrorMessage(error),
                    });
                    console.error(error);
                }
            });
            return [2 /*return*/];
        });
    }); }, [office, startDate]);
    useEffect(function () {
        if (!office || !isUpdated) {
            return;
        }
        fetchPlanningShifts();
        setIsUpdated(false);
    }, [office, startDate, fetchPlanningShifts, isUpdated]);
    return (_jsxs(_Fragment, { children: [_jsxs("div", __assign({ className: "flex items-center gap-6 p-6" }, { children: [_jsx(Card, __assign({ className: "flex-none w-[330px] bg-gray-50 border border-gray-200 rounded-lg h-[77px]" }, { children: _jsx(PlanningOfficeSelect, { regions: regions, office: office, onChangeOffice: setOffice, setIsUpdated: setIsUpdated, appContext: AppContext }) })), _jsx(Card, __assign({ className: "flex-grow w-full bg-white border border-gray-200 rounded" }, { children: _jsx(PlanningSummarySection, { startDate: startDate, summary: (_b = data === null || data === void 0 ? void 0 : data.summary) !== null && _b !== void 0 ? _b : null, onClickViewProductionDemand: onClickViewProductionDemand }) }))] })), _jsx("div", __assign({ className: "flex items-center gap-6 px-6" }, { children: _jsx(PlanningDateSelect, { startDate: startDate, onChangeStartDate: function (date) {
                        setStartDate(date);
                        setIsUpdated(true);
                    } }) })), isLoading ? (_jsx("div", __assign({ className: "flex-grow m-6 flex items-center justify-center border border-gray-200 rounded-tl bg-gray-50" }, { children: _jsx("div", __assign({ className: "w-[32px] h-[32px] rounded-full bg-gray-200 flex items-center justify-center" }, { children: _jsx("img", { src: LoadingGif, alt: "Loading ...", className: "w-[10px]" }) })) }))) : (_jsxs(_Fragment, { children: [_jsx("div", __assign({ className: "flex-grow m-6 overflow-hidden" }, { children: _jsx(PlanningCalendar, { office: office, startDate: startDate, employees: (_c = data === null || data === void 0 ? void 0 : data.employees) !== null && _c !== void 0 ? _c : [], holidays: (_d = data === null || data === void 0 ? void 0 : data.holidays) !== null && _d !== void 0 ? _d : [], onRefresh: fetchPlanningShifts, isProfileDrawerOpen: isProfileDrawerOpen, setIsProfileDrawerOpen: setIsProfileDrawerOpen, setSelectedEmployee: setSelectedEmployee }) })), _jsx(PlanningFooterSection, { officeId: office === null || office === void 0 ? void 0 : office.id })] })), noOfficeModalOpen && (_jsx(NoOffice, { isOpen: noOfficeModalOpen, onClose: function () { return setNoOfficeModalOpen(false); } })), isProfileDrawerOpen && (_jsx(ProfileDrawerModal, { office: office, isOpen: isProfileDrawerOpen, setIsOpen: setIsProfileDrawerOpen, handleClose: function () { return setIsProfileDrawerOpen(false); }, selectedEmployee: selectedEmployee, officeId: office.id, setIsUpdated: setIsUpdated }))] }));
};
export default TimelineView;
