var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from 'react';
import { format, getDay, parse } from 'date-fns';
import { PlanningCalendarDateShiftPopup } from './PlanningCalendarDateShiftPopup';
import Popover from 'components/shared/Popover';
import PlanningCalendarDeleteShift from './PlanningCalendarDeleteShift';
export var PlanningCalendarShiftItem = function (_a) {
    var office = _a.office, colIndex = _a.colIndex, date = _a.date, holiday = _a.holiday, employee = _a.employee, isProcessing = _a.isProcessing, isDesignatedAsHoliday = _a.isDesignatedAsHoliday, settings = _a.settings, setIsProcessing = _a.setIsProcessing, onToggleDateShift = _a.onToggleDateShift, onDeleteDateShift = _a.onDeleteDateShift, onUpdateDateShift = _a.onUpdateDateShift;
    var _b = useState(false), showPopover = _b[0], setShowPopover = _b[1];
    var _c = useState(false), isDeleteShiftModalOpen = _c[0], setIsDeleteShiftModalOpen = _c[1];
    var formattedDate = format(date, 'yyyy-MM-dd');
    var shift = employee.shifts[formattedDate];
    function convertTo12HourFormat(time) {
        var parsedTime = parse(time, 'HH:mm:ss', new Date());
        var formattedTime = format(parsedTime, 'ha');
        return formattedTime.toUpperCase();
    }
    var renderTimeRange = function (startAt, endAt) {
        try {
            return [
                convertTo12HourFormat(startAt),
                convertTo12HourFormat(endAt)
            ].filter(function (item) { return item; }).join(' - ');
        }
        catch (error) {
            console.error(error);
            return '';
        }
    };
    var handleSelectDateShift = function (shift) {
        if ((shift === null || shift === void 0 ? void 0 : shift.shift_type) === 'vacation') {
            return;
        }
        if (isProcessing) {
            console.log('Shift is in processing now...');
        }
        else {
            setShowPopover(true);
        }
    };
    var renderShiftBlock = function () {
        if (!shift) {
            return null;
        }
        if (shift.is_training) {
            return (_jsxs("div", __assign({ className: "p-2 h-full border border-[#9061F9] rounded bg-[#F6F5FF] text-[#9061F9]" }, { children: [_jsx("div", __assign({ className: "text-xs leading-4 font-bold" }, { children: "Training" })), _jsx("div", __assign({ className: "text-xs leading-4 text-gray-500 font-light whitespace-nowrap" }, { children: renderTimeRange(shift.start_at, shift.end_at) }))] })));
        }
        if (shift.shift_type === 'work' || shift.shift_type === 'extended' || shift.shift_type === 'regular' || shift.shift_type === 'short') {
            return (_jsxs("div", __assign({ className: "p-2 h-full border rounded ".concat(shift.working_duration >= 8 ? 'border-[#0E9F6E] bg-[#F3FAF7] text-[#0E9F6E]' : 'border-[#3F83F8] bg-[#EBF5FF] text-[#3F83F8]') }, { children: [_jsxs("div", __assign({ className: "text-xs leading-4 font-bold" }, { children: [shift.working_duration, " hours"] })), _jsx("div", __assign({ className: "text-xs leading-4 text-gray-500 font-light whitespace-nowrap" }, { children: renderTimeRange(shift.start_at, shift.end_at) }))] })));
        }
        if (shift.shift_type === 'vacation') {
            return (_jsx("div", __assign({ className: "p-2 h-[50px] border border-[#F05252] rounded bg-[#FDF2F2] text-[#F05252]" }, { children: _jsx("div", __assign({ className: "text-xs leading-4 font-bold" }, { children: "Vacation" })) })));
        }
        if (shift.shift_type === 'holiday') {
            return (_jsxs("div", __assign({ className: "p-2 h-[50px] border border-[#8E4B10] rounded bg-[#FDFDEA] text-[#8E4B10]" }, { children: [_jsx("div", __assign({ className: "text-xs leading-4 font-bold" }, { children: "Holiday" })), holiday && (_jsx("div", __assign({ className: "text-xs leading-4 text-gray-500 font-light whitespace-nowrap" }, { children: holiday.name })))] })));
        }
        return null;
    };
    return (_jsxs(_Fragment, { children: [_jsx("div", __assign({ className: "flex-1 border-gray-200 border-b bg-gray-50 h-[56px] ".concat(colIndex === 6 ? '' : 'border-r', " ").concat(isProcessing ? 'cursor-not-allowed' : 'cursor-pointer'), onClick: function () { return handleSelectDateShift(shift); } }, { children: _jsx(Popover, __assign({ open: showPopover, eventModalPosition: getDay(date) < 4 ? 'right-top' : 'left-top', hasArrow: true, className: "absolute z-10 bg-white rounded-md p-6 w-[289px] h-[354px]", content: _jsx(PlanningCalendarDateShiftPopup, { date: date, shift: shift, settings: settings, isDesignatedAsHoliday: isDesignatedAsHoliday, onDeleteDateShift: function () { return setIsDeleteShiftModalOpen(true); }, onUpdateDateShift: onUpdateDateShift }), onClickOutside: function () { return setShowPopover(false); } }, { children: _jsx("div", __assign({ className: "p-[3px] hover:shadow-[inset_0px_0px_0px_2px_#007AFF] ".concat(shift && (shift.is_hidden || shift.shift_type === 'disabled') ? 'hidden' : '', " ").concat(isProcessing ? 'opacity-50' : '') }, { children: renderShiftBlock() })) }), formattedDate) }), "".concat(employee.external_id, "_").concat(formattedDate)), _jsx(PlanningCalendarDeleteShift, { isOpen: isDeleteShiftModalOpen, onClose: function (status) {
                    setIsDeleteShiftModalOpen(false);
                    if (status === 'Cancel')
                        setShowPopover(true);
                }, onSuccess: function () { return onDeleteDateShift(date); }, onHideShow: function (isHidden) { return onToggleDateShift(date, isHidden); }, setIsProcessing: setIsProcessing, office: office, employee: employee, date: date })] }));
};
