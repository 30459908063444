import { createAsyncAction } from '@/redux/root';
import { nameSpace } from './nameSpace';
import { settingsReducer } from './settings-reducer';
import { settingsWatcher } from './settings-saga';
import { settingsSelector } from './settings-selector';
import { createSelector } from '@reduxjs/toolkit';
import { planBuilderConstants } from '@/modules/AdminTools/lib/constants';

const { AREA_TARGET_TYPE_VALUE, REP_TARGET_TYPE_VALUE } = planBuilderConstants;

export const salesSettingsNameSpace = `${nameSpace}/sales-settings`;

export const updateSalesSettingsAsync = createAsyncAction(
  `${salesSettingsNameSpace}/UPDATE_SALES_SETTINGS`,
);

export const createSalesSettingsAsync = createAsyncAction(
  `${salesSettingsNameSpace}/CREATE_SALES_SETTINGS`,
);

export const removeSalesSettingsAsync = createAsyncAction(
  `${salesSettingsNameSpace}/REMOVE_SALES_SETTINGS`,
);

export const requestSalesSettingsAsync = createAsyncAction(
  `${salesSettingsNameSpace}/REQUEST_SALES_SETTINGS`,
);

const NAME = 'salesSettings';

export const salesSettingsReducer = {
  [NAME]: settingsReducer(salesSettingsNameSpace, {
    updateAsync: updateSalesSettingsAsync,
    createAsync: createSalesSettingsAsync,
    removeAsync: removeSalesSettingsAsync,
    requestsAsync: requestSalesSettingsAsync,
  }),
};

export function* salesSettingsWatcher() {
  yield settingsWatcher('sales-settings', {
    removeAsync: removeSalesSettingsAsync,
    updateAsync: updateSalesSettingsAsync,
    createAsync: createSalesSettingsAsync,
    requestsAsync: requestSalesSettingsAsync,
  });
}

export const salesSettingsSelector = settingsSelector(NAME);

export const salesSettingsByAreaSelector = createSelector(
  salesSettingsSelector,
  (salesSettings) => salesSettings.filter((salesSetting) => salesSetting.target_type == AREA_TARGET_TYPE_VALUE),
);

export const salesSettingsByRepSelector = createSelector(
  salesSettingsSelector,
  (salesSettings) => salesSettings.filter((salesSetting) => salesSetting.target_type == REP_TARGET_TYPE_VALUE),
);
