var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import ProductionDemandImage from 'assets/image/production-demand.png';
import { ProductionDemandUploadButton } from './ProductionDemandUploadButton';
export var ProductionDemandUploadView = function (_a) {
    var isUploading = _a.isUploading, onUpload = _a.onUpload;
    return (_jsx("div", __assign({ className: "bg-white rounded-xl border border-gray-200 p-8 grow overflow-hidden" }, { children: _jsxs("div", __assign({ className: "flex flex-col items-center h-full overflow-auto" }, { children: [_jsx("div", __assign({ className: "text-[32px] font-light mb-2" }, { children: "Start tracking your weekly production targets" })), _jsx("div", __assign({ className: "text-[20px] text-text-default font-light max-w-[820px] text-center mb-6" }, { children: "Tracking your weekly production targets has never been easier. God creates dinosaurs. God destroys dinosaurs. God creates Man." })), _jsx("img", { src: ProductionDemandImage, alt: "Production Demand" }), _jsx(ProductionDemandUploadButton, { isUploading: isUploading, onUpload: onUpload })] })) })));
};
