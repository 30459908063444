import { memo } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import { AppBar, Main, Footer, TopNav, Toasts } from '.';

const Layout = ({ user, isAuthenticated }) => (
  <div className="flex flex-col min-h-screen bg-gray-50">
    <AppBar user={user} isAuthenticated={isAuthenticated} />
    {isAuthenticated && (
      <TopNav user={user} />
    )}
    <Main />
    <Footer />
    {createPortal(<Toasts />, document.body)}
  </div>
);

Layout.propTypes = {
  user: PropTypes.object,
  isAuthenticated: PropTypes.bool,
};

export default memo(Layout);
