var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState, useEffect } from 'react';
import { Navbar, Navbars, AptiveIcon } from '@aptive-env/storybook';
import DetailHeader from './DetailHeader';
import DetailPersonalInfo from './DetailPersonalInfo';
import DetailLicensing from './DetailLicensing';
import DetailNotes from './DetailNotes';
import DeleteLicenseModal from '../DeleteLicense';
import { fetchSalesRepresentativeProfile, fetchSalesRepresentativeLicenses } from 'services';
var ViewDetailDrawer = function (_a) {
    var isOpen = _a.isOpen, onClose = _a.onClose, salesRepresentative = _a.salesRepresentative, salesRepresentativeLicenses = _a.salesRepresentativeLicenses, setSalesRepresentativeLicenses = _a.setSalesRepresentativeLicenses, states = _a.states, onLicenseAdd = _a.onLicenseAdd, onLicenseEdit = _a.onLicenseEdit;
    var _b = useState(0), activeTab = _b[0], setActiveTab = _b[1];
    var _c = useState(null), profile = _c[0], setProfile = _c[1];
    var _d = useState(false), isDeleteLicenseModalOpen = _d[0], setIsDeleteLicenseModalOpen = _d[1];
    var _e = useState(null), selectedLicense = _e[0], setSelectedLicense = _e[1];
    var _f = useState(''), search = _f[0], setSearch = _f[1];
    useEffect(function () {
        if (!isOpen)
            setActiveTab(0);
        if (isOpen && salesRepresentative)
            loadSalesRepresentativeProfile(salesRepresentative.id);
        // eslint-disable-next-line
    }, [isOpen, salesRepresentative]);
    useEffect(function () {
        if (!isOpen)
            setSearch('');
        if (isOpen && salesRepresentative)
            loadSalesRepresentativeLicenses(salesRepresentative.id, search);
        // eslint-disable-next-line
    }, [isOpen, salesRepresentative, search]);
    var loadSalesRepresentativeProfile = function (id) { return __awaiter(void 0, void 0, void 0, function () {
        var profileResponse;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, fetchSalesRepresentativeProfile(id)];
                case 1:
                    profileResponse = _a.sent();
                    setProfile(__assign(__assign({}, profileResponse.result.rep), { team: salesRepresentative ? salesRepresentative.team : null }));
                    return [2 /*return*/];
            }
        });
    }); };
    var loadSalesRepresentativeLicenses = function (id, search) {
        if (search === void 0) { search = ''; }
        return __awaiter(void 0, void 0, void 0, function () {
            var licensesResponse;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, fetchSalesRepresentativeLicenses(id, search)];
                    case 1:
                        licensesResponse = _a.sent();
                        setSalesRepresentativeLicenses(licensesResponse.result.licenses);
                        return [2 /*return*/];
                }
            });
        });
    };
    var onLicenseDelete = function (license) {
        setSelectedLicense(license);
        setIsDeleteLicenseModalOpen(true);
    };
    var handleHideShowLicense = function (licenseId, isHidden) {
        var tempLicenses = __spreadArray([], salesRepresentativeLicenses, true);
        for (var _i = 0, tempLicenses_1 = tempLicenses; _i < tempLicenses_1.length; _i++) {
            var license = tempLicenses_1[_i];
            if (license.license_id === licenseId) {
                license.hidden = isHidden;
            }
        }
        setSalesRepresentativeLicenses(tempLicenses);
    };
    return (_jsxs(_Fragment, { children: [isOpen && (_jsxs("div", __assign({ className: "sidebar fixed top-0 left-0 h-full w-full z-[40]" }, { children: [_jsx("div", { className: "bg-[#00000040] absolute top-0 left-0 h-full w-full", onClick: onClose }), _jsxs("div", __assign({ className: "flex flex-col absolute right-0 bg-white h-full w-[666px] overflow-x-hidden transition-all duration-500" }, { children: [_jsxs("div", __assign({ className: "flex flex-col relative px-6 pt-6 pb-4 bg-gray-100 border-b border-gray-200" }, { children: [_jsx(DetailHeader, { profile: profile, viewFull: true }), _jsx(AptiveIcon, { className: "absolute top-4 right-5 w-[24px] h-[24px] stroke-[#9CA3AF] fill-none cursor-pointer", icon: "x", onClick: onClose })] })), _jsxs(Navbars, __assign({ className: 'gap-4 px-6', defaultValue: 0 }, { children: [_jsx(Navbar, { className: 'px-0 py-[26px]', title: 'Personal Info', onClick: function () { return setActiveTab(0); } }), _jsx(Navbar, { className: 'px-0 py-[26px]', title: 'Licensing', onClick: function () { return setActiveTab(1); } }), _jsx(Navbar, { className: 'px-0 py-[26px]', title: 'Notes', onClick: function () { return setActiveTab(2); } })] })), _jsxs("div", __assign({ className: "flex flex-1 overflow-hidden" }, { children: [activeTab === 0 && _jsx(DetailPersonalInfo, {}), activeTab === 1 &&
                                        _jsx(DetailLicensing, { licenses: salesRepresentativeLicenses, states: states, search: search, onSearch: setSearch, onAdd: onLicenseAdd, onEdit: onLicenseEdit, onDelete: onLicenseDelete }), activeTab === 2 && _jsx(DetailNotes, {})] }))] }))] }))), _jsx(DeleteLicenseModal, { isOpen: isDeleteLicenseModalOpen, onClose: function () { return setIsDeleteLicenseModalOpen(false); }, onHideShow: handleHideShowLicense, license: selectedLicense, salesRepresentative: salesRepresentative })] }));
};
export default ViewDetailDrawer;
