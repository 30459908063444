import PropTypes from 'prop-types';
import { memo } from 'react';
import ReactTooltip from 'react-tooltip';
import classNames from 'classnames';

const TooltipText = ({
  id,
  text,
  message,
  event,
  allowHtml,
}) => (
  <div data-for={id} data-tip={message} className={classNames('inline', event === 'click' ? 'cursor-pointer' : '')}>
    {text}
    <ReactTooltip
      className="normal-case"
      id={id}
      place="top"
      effect="solid"
      event={event}
      html={allowHtml}
    />
  </div>
);

TooltipText.propTypes = {
  id: PropTypes.string.isRequired,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  message: PropTypes.string.isRequired,
  event: PropTypes.string,
  allowHtml: PropTypes.bool,
};

export default memo(TooltipText);
