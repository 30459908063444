import React from 'react';
import { useDraggable } from '@dnd-kit/core';

const DraggableItem = ({ field }) => {
  const { attributes, listeners, setNodeRef } = useDraggable({ id: field.type });

  return (
    <li
      ref={setNodeRef}
      {...attributes}
      {...listeners}
      className={`cursor-pointer p-2 border rounded mb-2 bg-gray-100 `}
    >
      {field.label}
    </li>
  );
};

export default DraggableItem;
