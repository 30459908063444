import React from 'react';
import { CustomFormElement } from '@/components/common';
import { useFormContext } from 'react-hook-form';
import { planBuilderConstants } from '@/modules/AdminTools/lib/constants/plan-builder';

const {
  PLAN_UI_ELEMENTS,
  UI_ELEMENTS_NAME,
  UI_ELEMENTS_LABEL,
  UI_ELEMENTS_IS_ACTIVE,
  UI_ELEMENTS_IS_REQUIRED,
  AVAILABLE_UI_FIELDS,
  UI_ELEMENTS_FIELD_LABEL,
  UI_ELEMENTS_FIELD_NAME_LABEL,
  UI_ELEMENTS_IS_ACTIVE_LABEL,
  UI_ELEMENTS_IS_REQUIRED_LABEL,
} = planBuilderConstants;

const FileUpload = ({ field, index }) => {
  const { register } = useFormContext();

  return (
    <div className="mb-4">
      <h4 className="text-gray-700">{`${index + 1}. ${AVAILABLE_UI_FIELDS[field.type]}`}</h4>
      <CustomFormElement
        type="text"
        name={`${PLAN_UI_ELEMENTS}.${index}.${UI_ELEMENTS_NAME}`}
        formElementWrapperClassName="mt-4"
        className="border p-2 rounded w-full"
        register={register}
        label={UI_ELEMENTS_FIELD_NAME_LABEL}
        required
      />
      <CustomFormElement
        type="text"
        name={`${PLAN_UI_ELEMENTS}.${index}.${UI_ELEMENTS_LABEL}`}
        formElementWrapperClassName="mt-4"
        className="border p-2 rounded w-full"
        register={register}
        label={UI_ELEMENTS_FIELD_LABEL}
        required
      />
      <CustomFormElement
        type="checkbox"
        name={`${PLAN_UI_ELEMENTS}.${index}.${UI_ELEMENTS_IS_ACTIVE}`}
        formElementWrapperClassName="mt-4"
        className="inline-flex w-4 h-4 text-aptiveblue-600 border-gray-300 rounded"
        register={register}
        label={UI_ELEMENTS_IS_ACTIVE_LABEL}
      />
      <CustomFormElement
        type="checkbox"
        name={`${PLAN_UI_ELEMENTS}.${index}.${UI_ELEMENTS_IS_REQUIRED}`}
        formElementWrapperClassName="mt-4"
        className="inline-flex w-4 h-4 text-aptiveblue-600 border-gray-300 rounded"
        register={register}
        label={UI_ELEMENTS_IS_REQUIRED_LABEL}
      />
    </div>
  );
};

export default FileUpload;
