var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useRef, useEffect } from 'react';
import { Button } from '@aptive-env/storybook';
var ConfirmModal = function (_a) {
    var title = _a.title, message = _a.message, cancelLabel = _a.cancelLabel, confirmLabel = _a.confirmLabel, confirmDisabled = _a.confirmDisabled, onCancel = _a.onCancel, onConfirm = _a.onConfirm;
    var modalRef = useRef(null);
    useEffect(function () {
        var listener = function (event) {
            // Do nothing if clicking ref's element or descendent elements
            var el = modalRef === null || modalRef === void 0 ? void 0 : modalRef.current;
            if (!el || el.contains(event.target)) {
                return;
            }
            onCancel();
        };
        document.addEventListener('mousedown', listener);
        document.addEventListener('touchstart', listener);
        return function () {
            document.removeEventListener('mousedown', listener);
            document.removeEventListener('touchstart', listener);
        };
    }, [modalRef, onCancel]);
    return (_jsxs(_Fragment, { children: [_jsxs("div", __assign({ className: "absolute translate-x-[-50%] translate-y-[-50%] left-1/2 top-1/2 flex flex-col bg-white z-50 p-6 shadow-[0px_3px_8px_0px_rgba(87,102,117,0.15)] rounded-lg w-[380px]", ref: modalRef }, { children: [_jsxs("div", __assign({ className: "flex flex-col gap-4" }, { children: [_jsx("div", __assign({ className: "flex items-center gap-1.5" }, { children: _jsx("h1", __assign({ className: "text-lg text-gray-600 font-semibold" }, { children: title })) })), _jsx("h1", __assign({ className: "text-base text-gray-600" }, { children: message }))] })), _jsx(Button, __assign({ variant: "default", className: "mt-6 mb-2", onClick: onConfirm, disabled: confirmDisabled }, { children: confirmLabel !== null && confirmLabel !== void 0 ? confirmLabel : 'Confirm' })), _jsx(Button, __assign({ variant: "dark", onClick: onCancel }, { children: cancelLabel !== null && cancelLabel !== void 0 ? cancelLabel : 'Cancel' }))] })), _jsx("div", { className: "absolute top-0 left-0 bottom-0 right-0 bg-[#929CA859] h-full z-[49]" })] }));
};
export default ConfirmModal;
