var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Toast } from '@aptive-env/storybook';
import { useToast } from 'hooks/useToast';
export var GlobalToast = function () {
    var _a, _b;
    var _c = useToast(), toast = _c.toast, setToast = _c.setToast;
    if (!toast) {
        return null;
    }
    var message = toast.message, toastProps = __rest(toast, ["message"]);
    return (_jsx("div", __assign({ className: "fixed top-3 right-3 flex flex-col gap-2 z-[100]" }, { children: _jsx(Toast, __assign({}, toastProps, { isIcon: (_a = toastProps.isIcon) !== null && _a !== void 0 ? _a : true, dismissible: (_b = toastProps.dismissible) !== null && _b !== void 0 ? _b : true, onDismiss: toastProps.onDismiss ? toastProps.onDismiss : function () { return setToast(null); } }, { children: message })) })));
};
