import {
  agreementLengthsReducer,
  agreementLengthWatcher,
} from './agreement-lengths';
import { customGroupTypesReducer, customGroupTypeWatcher } from './custom-group-types';
import { customGroupsReducer, customGroupWatcher } from './custom-groups';
import { planCategoriesReducer, planCategoryWatcher } from './plan-categories';
import {
  planPricingLevelsReducer,
  planPricingLevelWatcher,
} from './plan-pricing-levels';
import {
  planServiceFrequenciesReducer,
  planServiceFrequencyWatcher,
} from './plan-service-frequencies';
import { planStatusesReducer, planStatusWatcher } from './plan-statuses';
import {
  targetContractValuesReducer,
  targetContractValueWatcher,
} from './target-contract-values';
import {
  salesSettingsReducer,
  salesSettingsWatcher,
} from './sales-settings';

export const canEditReducers = {
  ...planPricingLevelsReducer,
  ...planServiceFrequenciesReducer,
  ...planCategoriesReducer,
  ...planStatusesReducer,
  ...agreementLengthsReducer,
  ...targetContractValuesReducer,
  ...salesSettingsReducer,
  ...customGroupTypesReducer,
  ...customGroupsReducer,
};

export const canEditWatchers = () => ([
  planPricingLevelWatcher(),
  planServiceFrequencyWatcher(),
  planStatusWatcher(),
  planCategoryWatcher(),
  agreementLengthWatcher(),
  targetContractValueWatcher(),
  salesSettingsWatcher(),
  customGroupTypeWatcher(),
  customGroupWatcher(),
]);
