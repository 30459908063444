import { useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useFeatureFlag } from 'configcat-react';

import { createPlanAsync, planSelector, plansSelector, updatePlanAsync } from '@/modules/AdminTools/redux/planBuilder/plans';
import { updatingPlanLoadingSelector } from '@/redux/loading';
import { editPlan } from '@/modules/AdminTools/routing/plan-builder';
import Settings from './Settings';
import FormControls from '../FormControls';
import DefaultAreaPlan from './DefaultAreaPlan';
import UIElementConfigurator from './UIElementConfigurator';
import UIElementsHeader from './Headers/UIElements';
import { planBuilderConstants } from '@/modules/AdminTools/lib/constants/plan-builder';

const { PLAN_UI_ELEMENTS, PLAN_UI_ELEMENTS_FEATURE_FLAG_NAME } = planBuilderConstants;

const createPlanDefaultValues = {
  name: '',
  plan_category_ids: [],
  sales_channel_ids: [],
  plan_status_id: '',
  plan_service_frequency_id: '',
  agreement_length_ids: [],
  start_on: '',
  end_on: '',
  default_area_plan: {
    area_plan_pricings: [],
    addons: [],
    service_product_ids: [],
  },
  area_plans: [],
  [PLAN_UI_ELEMENTS]: [],
};

const createButton = 'Create new plan';
const updateButton = 'Update plan details';

const PlanDetails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const urlParams = useParams();
  const planId = urlParams?.id;

  const createPlanRequest = (data) => dispatch(createPlanAsync.request(data));
  const updatePlanRequest = (data) => dispatch(updatePlanAsync.request(data));

  const updatePlanDefaultValues = useSelector(planSelector);

  const planErrors = useSelector((state) => state?.errors?.errors?.updatePlan);
  const plans = useSelector(plansSelector);

  const isUpdating = useSelector(updatingPlanLoadingSelector);

  const [isSubmitted, setIsSubmitted] = useState(false);

  const methods = useForm({
    defaultValues: planId ? updatePlanDefaultValues : createPlanDefaultValues,
  });
  const onSubmit = planId ? updatePlanRequest : createPlanRequest;

  const successfulUpdate = useMemo(
    () => isSubmitted && !isUpdating && !planErrors,
    [isUpdating, planErrors, isSubmitted],
  );

  const { value: isPlanUIElementsEnabled } = useFeatureFlag(
    PLAN_UI_ELEMENTS_FEATURE_FLAG_NAME,
    false,
  );

  useEffect(() => {
    if (!successfulUpdate) {
      return;
    }

    // Notification of success.
    if (!planId) {
      const id = plans[plans.length - 1].id;
      navigate(editPlan(id));
    } else {
      methods.reset(updatePlanDefaultValues);
    }
  }, [plans, navigate, planId, successfulUpdate, methods, updatePlanDefaultValues]);

  useEffect(() => {
    if (planErrors && isSubmitted) {
      setIsSubmitted(false);
    }
  }, [setIsSubmitted, planErrors, isSubmitted]);

  const handleSubmit = (data) => {
    if (isUpdating) {
      return;
    }

    data[PLAN_UI_ELEMENTS] = data[PLAN_UI_ELEMENTS].map((element, index) => ({
      ...element,
      sort_order: index,
    }));

    onSubmit({ plan: data });

    setIsSubmitted(true);
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(handleSubmit)}>
        <div>
          <FormControls submitText={planId ? updateButton : createButton} />
          <Settings />
          <hr className="mt-4" />
          <DefaultAreaPlan />
          <hr className="mt-4" />
          {isPlanUIElementsEnabled && <UIElementConfigurator {...{ UIElementsHeader }} />}
        </div>
      </form>
    </FormProvider>
  );
};

export default PlanDetails;
