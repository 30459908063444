import { useDispatch } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { useEffect } from 'react';

import {
  requestSalesChannelsAsync,
} from '@/modules/AdminTools/redux/planBuilder/sales-channels';

const SalesChannelsManager = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(requestSalesChannelsAsync.request());
  }, [dispatch]);

  return (
    <div className="m-6">
      <Outlet />
    </div>
  );
};

export default SalesChannelsManager;
