var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Pagination } from '@aptive-env/storybook';
import styles from './index.module.scss';
var TableFooter = function (_a) {
    var page = _a.page, setPage = _a.setPage, pageSize = _a.pageSize, setPageSize = _a.setPageSize, totalResults = _a.totalResults;
    return (_jsx("div", __assign({ className: "flex px-4 py-3 bg-white rounded-b-lg border border-gray-200 ".concat(styles.paginationWrapper) }, { children: _jsx(Pagination, { currentPage: page, totalResults: totalResults, perPage: pageSize, pageSizeOptions: [10, 20, 30], onPerPageChange: setPageSize, onPageChange: setPage }) })));
};
export default TableFooter;
