import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { salesChannelsSelector } from '@/modules/AdminTools/redux/planBuilder/sales-channels';
import EmptySalesChannels from './EmptySalesChannels';

const EmptyCheck = ({ children, onCreateSalesChannelModalOpen }) => {
  const salesChannels = useSelector(salesChannelsSelector);

  const loading = useSelector((state) => state?.loading);
  const isLoadingSalesChannels = loading?.salesChannels?.isLoading || false;

  return (
    <>{
      !(isLoadingSalesChannels || salesChannels?.length)
        ? <EmptySalesChannels onCreateSalesChannelModalOpen={onCreateSalesChannelModalOpen} />
        : children
    }
    </>
  );
};

EmptyCheck.propTypes = {
  children: PropTypes.node.isRequired,
  onCreateSalesChannelModalOpen: PropTypes.func,
};

export default EmptyCheck;
