import { AxiosError } from 'axios';
/**
 * Extracts an error message from any type of error object.
 * @param error
 * @param defaultMessage
 * @returns
 */
export function getErrorMessage(error, defaultMessage) {
    var _a, _b;
    // Handle AxiosError explicitly first
    if (error instanceof AxiosError) {
        if (((_a = error.response) === null || _a === void 0 ? void 0 : _a.data) && error.response.data.result && typeof error.response.data.result.message === 'string') {
            return error.response.data.result.message;
        }
        if (((_b = error.response) === null || _b === void 0 ? void 0 : _b.data) && typeof error.response.data.message === 'string') {
            return error.response.data.message;
        }
        return error.message; // Fallback to Axios' error message
    }
    // Handle general Error instances
    if (error instanceof Error) {
        return error.message;
    }
    // Handle primitive types like string or number
    if (typeof error === 'string' || typeof error === 'number') {
        return error.toString();
    }
    // Default error message for unknown types
    return defaultMessage !== null && defaultMessage !== void 0 ? defaultMessage : 'An unknown error occurred';
}
