import { formatDateDisplay } from '@/lib';
import { salesChannelsConstants } from '@/modules/AdminTools/lib/constants';

const {
  ID, ID_LABEL, CREATION_DATE, CREATION_DATE_LABEL, STATUS,
  STATUS_LABEL, NAME, NAME_LABEL, EDIT, DELETE,
} = salesChannelsConstants;

const table = [
  {
    label: ID_LABEL,
    sortBy: ID,
    render: ({ salesChannel }) => salesChannel[ID],
  },
  {
    label: NAME_LABEL,
    sortBy: NAME,
    render: ({ salesChannel }) => salesChannel[NAME],
  },
  {
    label: STATUS_LABEL,
    sortBy: STATUS,
    render: ({ salesChannel }) => salesChannel[STATUS] ? 'Active' : 'Inactive',
  },
  {
    label: CREATION_DATE_LABEL,
    sortBy: CREATION_DATE,
    render: ({ salesChannel }) => formatDateDisplay(salesChannel[CREATION_DATE]),
  },
  {
    label: '',
    render: ({ remove }) => (
      <div className="cursor-pointer text-primary-300" onClick={remove}>
        {DELETE}
      </div>
    ),
  },
  {
    label: '',
    render: ({ edit }) => (
      <div className="cursor-pointer text-primary-300" onClick={edit}>
        {EDIT}
      </div>
    ),
  },
];

export default table;
