export const ledgerConstants = {
  LEDGER_SEARCH_NAME: 'ledger',
  LEDGER_TYPE_FILTER_NAME : 'ledger_type',

  LEDGER_HISTORY_SEARCH_NAME: 'ledger_history',

  PAYMENT_METHOD_BM_CREDIT_CARD: '5',

  DEALER_NAME: 'dealer_id',
  DEALER_LABEL: 'Dealer',
  BRANCH_NAME: 'branch_id',
  BRANCH_LABEL: 'Branch',
  TEAM_NAME: 'team_id',
  TEAM_LABEL: 'Team',
  PARTNERSHIP_NAME: 'partnership_id',
  PARTNERSHIP_LABEL: 'Partnership',
  MD_USER_ID_NAME: 'md_user_id',
  MD_USER_ID_LABEL: 'MD Holder',
  MD_USER_NAME_NAME: 'md_user_name',
  APARTMENT_IDS_NAME: 'apartment_ids',
  APARTMENT_ID_LABEL: 'Apartment (Unit ID)',
  APARTMENT_ID_NAME: 'apartment_id',
  VENDOR_NAME: 'vendor_number',
  VENDOR_LABEL: 'Vendor number',
  PAYEE_NAME: 'payee',
  PAYEE_LABEL: 'Payee',
  PAYMENT_TYPE_NAME: 'payment_type_id',
  PAYMENT_TYPE_LABEL: 'Type of Payment',
  PAYMENT_METHOD_NAME: 'payment_method_id',
  PAYMENT_METHOD_LABEL: 'Payment Method',
  AMOUNT_TO_PAY_NAME: 'amount_to_pay',
  AMOUNT_TO_PAY_LABEL: 'Amount to Pay',
  FURNITURE_DAMAGED_NAME: 'furniture_damaged',
  FURNITURE_DAMAGED_LABEL: 'Furniture Damages',
  REP_UTILITIES_NAME: 'rep_utilities',
  REP_UTILITIES_LABEL: 'Rep Utilities',
  APARTMENT_CHARGES_NAME: 'apartment_charges',
  APARTMENT_CHARGES_LABEL: 'Apartment Charges',
  AMOUNT_PAID_NAME: 'amount_paid',
  AMOUNT_PAID_LABEL: 'Amount Paid',
  DATE_PAID_NAME: 'date_paid',
  DATE_PAID_LABEL: 'Date Paid',
  DATE_DUE_NAME: 'date_due',
  DATE_DUE_LABEL: 'Date Due',
  IS_OVERNIGHT_PAYMENT_REQUEST_NAME: 'is_overnight_payment_request',
  IS_OVERNIGHT_PAYMENT_REQUEST_LABEL: 'Overnight Payment',

  OVERNIGHT_PAYMENT_TOOLTIP: 'It will prioritize the associated ledger entry for overnight payment processing.',

  CANCEL_LEDGER_FEATURE_FLAG_NAME: 'isCancellingLedgerLinesEnabled',
  LEDGER_MD_HOLDER_FIELD_FLAG_NAME: 'isLedgerMdHolderFieldEnabled',
};

export const payStatus = [
  { name: 'Cancelled', value: 3 },
  { name: 'Not Paid', value: 4 },
  { name: 'Paid', value: 1 },
  { name: 'Returned', value: 2 },
];
