import { useSelector } from 'react-redux';
import { useCallback, useMemo } from 'react';

import {
  createSalesSettingsAsync,
  removeSalesSettingsAsync,
  salesSettingsByAreaSelector,
  updateSalesSettingsAsync,
} from '@/modules/AdminTools/redux/planBuilder/can-edit-settings/sales-settings';
import AreaSelect from './AreaSelect';
import EditArea from './EditArea';
import { selectAreasList } from '@/redux/areas-new';
import EditDataTable from '@/modules/AdminTools/components/EditDataTable';
import { AreasProvider } from './AreaContext';
import { planBuilderConstants } from '@/modules/AdminTools/lib/constants';
import { object, number } from 'yup';

const {
  TARGET_CONTRACT_VALUE,
  TARGET_CONTRACT_VALUE_LABEL,
  TWENTY_FOUR_MONTH_THRESHOLD_VALUE,
  TWENTY_FOUR_MONTH_THRESHOLD_LABEL,
  TARGET_ID_VALUE,
  TARGET_TYPE_VALUE,
  AREA_TARGET_TYPE_VALUE,
  AREA_LABEL,
} = planBuilderConstants;

const fields = [
  {
    label: AREA_LABEL,
    name: TARGET_ID_VALUE,
    CreateRender: AreaSelect,
    Edit: EditArea,
  },
  {
    label: TARGET_CONTRACT_VALUE_LABEL,
    name: TARGET_CONTRACT_VALUE,
    type: 'number',
    step: 0.01,
    required: true,
    min: 0,
    max: 999999,
  },
  {
    label: TWENTY_FOUR_MONTH_THRESHOLD_LABEL,
    name: TWENTY_FOUR_MONTH_THRESHOLD_VALUE,
    type: 'number',
    step: 0.01,
    required: false,
    min: 0,
    max: 100,
  },
];

const schema = object({
  [TARGET_ID_VALUE]: number().required(),
  [TARGET_CONTRACT_VALUE]:
    number()
      .transform((value, originalValue) => (originalValue.trim() === '' ? null : value))
      .max(999999, `The ${TARGET_CONTRACT_VALUE_LABEL} must be less than or equal to 999999`)
      .min(0, `The ${TARGET_CONTRACT_VALUE_LABEL} must be greater than or equal to 0`)
      .nullable()
      .required(`The ${TARGET_CONTRACT_VALUE_LABEL} is a required field`),
  [TWENTY_FOUR_MONTH_THRESHOLD_VALUE]:
    number()
      .transform((value, originalValue) => (originalValue.trim() === '' ? null : value))
      .max(100, `The ${TWENTY_FOUR_MONTH_THRESHOLD_LABEL} must be less than or equal to 100`)
      .min(0, `The ${TWENTY_FOUR_MONTH_THRESHOLD_LABEL} must be greater than or equal to 0`)
      .nullable(),
});

const sortOrders = [TARGET_ID_VALUE, TARGET_CONTRACT_VALUE, TWENTY_FOUR_MONTH_THRESHOLD_VALUE];

const title = 'Sales Settings by Area';

const name = 'salesSettingsByArea';

const defaultValues = {
  [TARGET_TYPE_VALUE]: AREA_TARGET_TYPE_VALUE,
  [TARGET_ID_VALUE]: '',
  [TARGET_CONTRACT_VALUE]: 0,
  [TWENTY_FOUR_MONTH_THRESHOLD_VALUE]: 0,
};

const searchPlaceholder = 'Search by area';

const SalesSettingsByArea = () => {
  const areas = useSelector(selectAreasList);

  const areaNameMap = useMemo(() => {
    const map = {};
    areas.forEach(({ area_id, area_name }) => {
      map[area_id] = area_name;
    });

    return map;
  }, [areas]);

  const getSortField = useCallback(
    (sortingBy) => {
      if (sortingBy === TARGET_ID_VALUE) {
        return (salesSettingsByArea) => {
          const areaId = salesSettingsByArea[TARGET_ID_VALUE];

          return areaNameMap[areaId];
        };
      }

      return (salesSettingsByArea) => salesSettingsByArea[sortingBy];
    },
    [areaNameMap],
  );

  const getSearchField = ({ target_id }) => areaNameMap[target_id];

  return (
    <AreasProvider>
      <EditDataTable
        {...{
          sortOrders,
          searchPlaceholder,
          getSearchField,
          defaultValues,
          title,
          fields,
          schema,
          name,
          getSortField,
        }}
        updateAsync={updateSalesSettingsAsync}
        removeAsync={removeSalesSettingsAsync}
        createAsync={createSalesSettingsAsync}
        selector={salesSettingsByAreaSelector}
      />
    </AreasProvider>
  );
};

export default SalesSettingsByArea;
