import { call, put, takeLatest } from 'redux-saga/effects';
import Api from '@/modules/AdminTools/api/planBuilder';
import { createRequestSaga } from '@/redux/helpers';
import {
  removeSalesChannelAsync,
  requestSalesChannelsAsync,
  updateSalesChannelAsync,
  createSalesChannelAsync,
} from './actions';
import { addErrorAction } from '@/redux/errors';
import { addToastsAction } from '@/redux/toasts';
import { toastType } from '@/components/common';
import { HttpStatusCode } from 'axios';

const createOnErrorHandler = (errKey) =>
  function* onError({ errors }, { action: { payload } = {} }) {
    yield put(addErrorAction({ errKey, error: errors }));
  };

function* requestSalesChannelsSaga() {
  const response = yield call(Api.getSalesChannels);

  yield put(requestSalesChannelsAsync.success(response));
}

function* updateSalesChannelSaga({ payload: { id, data, successCallback, failureCallback } }) {
  try {
    const response = yield call(Api.updateSalesChannel(id), data);

    yield put(updateSalesChannelAsync.success(response));

    yield put(addToastsAction([{
      type: toastType.SUCCESS,
      message: 'Updated sales channel successfully',
      details: null,
    }]));

    yield call(successCallback);
  } catch (error) {
    const { response: { status, data: { message } } } = error;

    yield put(addToastsAction([{
      type: toastType.ERROR,
      message: status === HttpStatusCode.UnprocessableEntity
        ? message
        : 'Server failed to update sales channel',
      details: null,
    }]));

    yield call(failureCallback);
  }
}

function* createSalesChannelSaga({ payload: { data, successCallback, failureCallback } }) {
  try {
    const response = yield call(Api.createSalesChannel, data);

    yield put(createSalesChannelAsync.success(response));

    yield put(addToastsAction([{
      type: toastType.SUCCESS,
      message: 'Created sales channel successfully',
      details: null,
    }]));

    yield call(successCallback);
  } catch (error) {
    const { response: { status, data: { message } } } = error;

    yield put(addToastsAction([{
      type: toastType.ERROR,
      message: status === HttpStatusCode.UnprocessableEntity
        ? message
        : 'Server failed to create sales channel',
      details: null,
    }]));

    yield call(failureCallback);
  }
}

function* removeSalesChannelSaga({ payload: { id } }) {
  yield call(Api.removeSalesChannel(id));

  yield put(removeSalesChannelAsync.success(id));

  yield put(addToastsAction([{
    type: toastType.SUCCESS,
    message: 'Removed sales channel successfully',
    details: null,
  }]));
}

export function* salesChannelsWatcher() {
  yield takeLatest(
    requestSalesChannelsAsync.request.type,
    createRequestSaga(requestSalesChannelsSaga, {
      keyNew: 'salesChannels',
      errKey: 'salesChannels',
      write: false,
    }),
  );

  yield takeLatest(
    updateSalesChannelAsync.request.type,
    createRequestSaga(updateSalesChannelSaga, {
      keyNew: 'updateSalesChannel',
      errKey: 'updateSalesChannel',
      write: false,
      onError: createOnErrorHandler('updateSalesChannel'),
    }),
  );

  yield takeLatest(
    createSalesChannelAsync.request.type,
    createRequestSaga(createSalesChannelSaga, {
      keyNew: 'updateSalesChannel',
      errKey: 'updateSalesChannel',
      write: false,
      onError: createOnErrorHandler('updateSalesChannel'),
    }),
  );

  yield takeLatest(
    removeSalesChannelAsync.request.type,
    createRequestSaga(removeSalesChannelSaga, {
      keyNew: 'salesChannel',
      errKey: 'salesChannel',
      write: false,
    }),
  );
}
