import { createSelector } from 'reselect';

import { initialState, SALES_CHANNELS } from './reducer';

const baseSalesChannelsSelector = (state) => state[SALES_CHANNELS];

export const salesChannelsSelector = createSelector(
  baseSalesChannelsSelector,
  (salesChannels) => salesChannels?.salesChannels || initialState.salesChannels,
);

export const salesChannelSelector = createSelector(
  baseSalesChannelsSelector,
  (salesChannels) => salesChannels?.salesChannel || initialState.salesChannel,
);
