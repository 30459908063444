import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import createPersistedState from './createPersistedState';
import createRootReducer from './createRootReducer';
import createRootSaga from './createRootSaga';
import { saveState } from './localStorage';

const createStore = () => {
  const sagaMiddleware = createSagaMiddleware();

  const store = configureStore({
    reducer: createRootReducer(),
    preloadedState: createPersistedState(),
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
      // TODO: this must be empty!
      //       See https://redux.js.org/faq/organizing-state#can-i-put-functions-promises-or-other-non-serializable-items-in-my-store-state
      serializableCheck: {
        ignoredPaths: [
          'campaigns.campaigns.create',
          'campaigns.campaigns.get',
          'campaigns.campaigns.remove',
          'campaigns.campaigns.update',
          'onboarding.nextStep.applyTransition',
          'onboarding.nextStep.declineTransition',
          'planBuilderAreaPlans.areaPlans',
          'planBuilderGroupPlans.groupPlans',
          'planBuilderSettings.settings.get',
          'planUpgradePaths.create',
          'planUpgradePaths.get',
          'planUpgradePaths.remove',
          'productCategories.categories.create',
          'productCategories.categories.get',
          'productCategories.categories.remove',
          'productSubCategories.sub_categories.get',
          'productSubCategories.sub_categories.getOptions',
          'products.products.create',
          'products.products.get',
          'products.products.remove',
          'promotions.promotions.create',
          'promotions.promotions.get',
          'promotions.promotions.remove',
          'promotions.promotions.update',
          'repDetails.details',
        ],
        ignoredActionPaths: [
          'payload.applyTransition',
          'payload.declineTransition',
          'payload.callback',
          'payload.successCallback',
          'payload.errorCallback',
          'payload.cancelToken',
        ],
      },
    }).concat(sagaMiddleware),
  });

  store.subscribe(() => {
    saveState(store.getState().auth);
  });

  sagaMiddleware.run(createRootSaga());

  return store;
};

export default createStore;
