import { CustomButton } from '@/components/common';
import { connect } from 'react-redux';
import ModalWrapper from './ModalWrapper';
import PropTypes from 'prop-types';
import { addFsExcludeClass } from '@/lib/utils';
import { CustomFormElement } from '@/components';
import { FormProvider, useForm } from "react-hook-form";
import { useCallback, useEffect } from 'react';
import { updateUserSalesChannelsAsync } from '@/redux/users';

const SalesChannelsManagementModal = ({
  isOpen,
  onClose,
  userId,
  userName,
  userChannelIds,
  salesChannels,
  areSalesChannelsLoading,
  updateUserSalesChannels
}) => {
  const SALES_CHANNELS_FIELD_NAME = 'sales_channel_ids';
  const methods = useForm({
    mode: 'all',
    reValidateMode: 'onChange',
  });

  const {
    getValues,
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = methods;
  const { [SALES_CHANNELS_FIELD_NAME]: selectedSalesChannels } = getValues();

  const onChangeChannels = (event) => {
    const { name, value } = event.target;

    setValue(name, value, { shouldValidate: true });
  };

  const handleSaveChannels = useCallback(() => {
    updateUserSalesChannels({
      userId: userId,
      salesChannelIds: selectedSalesChannels,
      successCallback: onClose,
    });
  }, [selectedSalesChannels, onClose]);

  useEffect(() => {
    if (Array.isArray(userChannelIds)) {
      setValue(SALES_CHANNELS_FIELD_NAME, userChannelIds, { shouldValidate: true });
    }
  }, [setValue, userChannelIds]);

  const salesChannelOptions =  salesChannels.map(({ id, name, status }) => {
    let label = name;

    if (!status) {
      label += ' (Inactive)';
    }

    return { value: id, label: label };
  });

  return (
    <>
      <ModalWrapper
        isOpened={isOpen}
        onCloseModal={onClose}
        width={'max-w-[1440px]'}
      >
        <div className="flex items-center py-3 px-3">
          <div className="p-6 flex flex-nowrap gap-x-4">
            <div className="grow">
              <div className="flex items-start justify-between">
                <h1 className={addFsExcludeClass("mb-2 text-lg font-medium text-gray-900")}>
                  Manage Sales Channels for {userName}
                </h1>
              </div>
              <FormProvider {...methods}>
                <form noValidate onSubmit={handleSubmit(handleSaveChannels)}>
                  <div className="flex flex-col gap-3">
                    <CustomFormElement
                      label="Sales Channels"
                      id={SALES_CHANNELS_FIELD_NAME}
                      name={SALES_CHANNELS_FIELD_NAME}
                      type="multiSelect"
                      isMulti={true}
                      errors={errors?.[SALES_CHANNELS_FIELD_NAME]}
                      loading={areSalesChannelsLoading}
                      placeholder='-- Select Sales Channels --'
                      options={salesChannelOptions}
                      onChange={onChangeChannels}
                      value={selectedSalesChannels}
                      required
                      register={register}
                      className={addFsExcludeClass()}
                      formElementWrapperClassName="text-black"
                    />
                    <div className="flex justify-end space-x-4">
                      <CustomButton className="px-6 font-normal" color="white" onClick={onClose}>
                        Cancel
                      </CustomButton>
                      <CustomButton className="px-6 font-normal" color={'green'} type="submit">
                        Update
                      </CustomButton>
                    </div>
                  </div>
                </form>
              </FormProvider>
            </div>
          </div>
        </div>
      </ModalWrapper>
    </>
  );
};

SalesChannelsManagementModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  userId: PropTypes.number,
  userName: PropTypes.string,
  userChannelIds: PropTypes.arrayOf(PropTypes.number),
  salesChannels: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      status: PropTypes.bool.isRequired,
      channel_ids: PropTypes.arrayOf(PropTypes.number),
    })
  ),
  areSalesChannelsLoading: PropTypes.bool,
  updateUserSalesChannels: PropTypes.func,
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
  updateUserSalesChannels: updateUserSalesChannelsAsync.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(SalesChannelsManagementModal);
