import { ModalWrapper } from '@/components';
import SalesChannelForm from './SalesChannelForm';
import PropTypes from 'prop-types';

const SalesChannelModal = ({ isOpen, onClose } ) => {
  return (
    <ModalWrapper isOpened={isOpen} onCloseModal={onClose}>
      <SalesChannelForm
        onClose={onClose}
      />
    </ModalWrapper>
  );
};

SalesChannelModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

export default SalesChannelModal;
