import SalesChannelsHeader from '../components/SalesChannelsHeader';
import EmptyCheck from '../components/EmptyCheck';
import SalesChannelsTable from '../components/SalesChannelsTable';
import SalesChannelModal from '../components/SalesChannelModal';
import { useCallback, useState } from 'react';
import { resetSelectedSalesChannel } from '@/modules/AdminTools/redux/planBuilder/sales-channels';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

const SalesChannels = ({ resetSelectedSalesChannel }) => {
  const [isSalesChannelModalOpen, setIsSalesChannelModalOpen] = useState(false);

  const onSalesChannelModalClose = useCallback(() => {
    setIsSalesChannelModalOpen(false);
  }, [setIsSalesChannelModalOpen]);

  const onCreateSalesChannelModalOpen = useCallback(() => {
    resetSelectedSalesChannel();
    setIsSalesChannelModalOpen(true);
  }, [resetSelectedSalesChannel, setIsSalesChannelModalOpen]);

  return (
    <div className="m-6">
      <SalesChannelsHeader onCreateSalesChannelModalOpen={onCreateSalesChannelModalOpen} />
      <EmptyCheck onCreateSalesChannelModalOpen={onCreateSalesChannelModalOpen}>
        <SalesChannelsTable setIsSalesChannelModalOpen={setIsSalesChannelModalOpen} />
      </EmptyCheck>
      <SalesChannelModal
        isOpen={isSalesChannelModalOpen}
        onClose={onSalesChannelModalClose}
      />
    </div>
  );
};

SalesChannels.propTypes = {
  resetSelectedSalesChannel: PropTypes.func,
};

const mapDispatchToProps = {
  resetSelectedSalesChannel,
};

export default connect(null, mapDispatchToProps)(SalesChannels);
