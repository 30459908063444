import { createReducer } from '@/redux/root';
import {
  salesChannelsNameSpace,
  requestSalesChannelsAsync,
  updateSalesChannelAsync,
  createSalesChannelAsync,
  removeSalesChannelAsync,
  setSelectedSalesChannel,
  resetSelectedSalesChannel,
} from './actions';

export const SALES_CHANNELS = 'salesChannels';

export const initialState = {
  salesChannels: [],
  salesChannel: {
    name: null,
    status: null,
    created_at: null,
  },
};

export const salesChannelsReducer = {
  [SALES_CHANNELS]: createReducer(
    salesChannelsNameSpace,
    initialState,
    {
      [requestSalesChannelsAsync.success]: ({ state, action: { payload } }) => {
        state.salesChannels = payload;
      },
      [updateSalesChannelAsync.success]: ({ state, action: { payload } }) => {
        state.salesChannel = payload;
        state.salesChannels.forEach((salesChannel, index) => {
          if (salesChannel.id === payload.id) {
            state.salesChannels[index] = payload;
          }
        });
      },
      [createSalesChannelAsync.success]: ({ state, action: { payload } }) => {
        state.salesChannel = payload;
        state.salesChannels.push(payload);
      },
      [removeSalesChannelAsync.success]: ({ state, action: { payload } }) => {
        state.salesChannels = state.salesChannels.filter(({ id }) => id !== payload);
      },
      [setSelectedSalesChannel]: ({ state, action: { payload } }) => {
        state.salesChannel = payload;
      },
      [resetSelectedSalesChannel]: ({ state }) => {
        state.salesChannel = initialState.salesChannel;
      },
    },
  ),
};
