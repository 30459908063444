import React from 'react';
import { useDroppable } from '@dnd-kit/core';

const DroppableArea = ({ children, hasFields }) => {
  const { setNodeRef } = useDroppable({ id: 'droppable' });

  return (
    <div ref={setNodeRef} className="w-2/3 border p-4 bg-gray-100 min-h-[400px]">
      {!hasFields && <h3 className="text-center mb-4">Click or drag into the area</h3>}
      {children}
    </div>
  );
};

export default DroppableArea;
