var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { AptiveIcon } from '@aptive-env/storybook';
import { Checked, Unchecked } from 'icons';
var MultiSelect = function (_a) {
    var icon = _a.icon, placeholder = _a.placeholder, maxVisibleOptions = _a.maxVisibleOptions, options = _a.options, value = _a.value, onChange = _a.onChange, onVisibleOptions = _a.onVisibleOptions, renderImage = _a.renderImage;
    var ref = useRef(null);
    var _b = useState(false), isExpanded = _b[0], setIsExpanded = _b[1];
    var _c = useState(''), searchText = _c[0], setSearchText = _c[1];
    var isAllOptionsSelected = useMemo(function () {
        return options.length === value.length;
    }, [options, value]);
    var filteredOptions = useMemo(function () {
        return options
            .filter(function (item) { return item.label.toLowerCase().includes(searchText.toLowerCase()); });
    }, [options, searchText]);
    var isOptionSelected = useCallback(function (option) {
        return value.some(function (item) { return item.value === option.value; });
    }, [value]);
    var handleClickOption = function (option) {
        if (isOptionSelected(option)) {
            onChange(value.filter(function (item) { return item.value !== option.value; }));
        }
        else {
            onChange(__spreadArray(__spreadArray([], value, true), [option], false));
        }
    };
    var handleClickSelectAll = function () {
        if (!isAllOptionsSelected) {
            onChange(options);
        }
        else {
            onChange([]);
        }
    };
    useEffect(function () {
        var handleScroll = function () {
            if (!onVisibleOptions) {
                return;
            }
            var container = ref.current;
            if (!container) {
                return;
            }
            var containerRect = container.getBoundingClientRect();
            var allItems = container.querySelectorAll('.item');
            var visibleOptions = [];
            allItems.forEach(function (item) {
                var itemRect = item.getBoundingClientRect();
                // Check if the item is within the container's visible area
                if (item instanceof HTMLElement &&
                    itemRect.top >= containerRect.top &&
                    itemRect.bottom <= containerRect.bottom) {
                    var value_1 = item.dataset.value;
                    var label = item.dataset.label;
                    var image = item.dataset.image;
                    visibleOptions.push({
                        value: Number(value_1),
                        label: label || '',
                        image: image
                    });
                }
            });
            onVisibleOptions(visibleOptions);
        };
        document.addEventListener('scroll', handleScroll, true);
        return function () {
            document.removeEventListener('scroll', handleScroll, true);
        };
    }, [onVisibleOptions]);
    useEffect(function () {
        var handleClickOutside = function (event) {
            if (event.target instanceof HTMLElement && ref.current && !ref.current.contains(event.target)) {
                event.stopPropagation();
                setIsExpanded(false);
            }
        };
        document.addEventListener('click', handleClickOutside, true);
        return function () {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, []);
    return (_jsxs("div", __assign({ className: "w-full relative" }, { children: [_jsxs("div", __assign({ className: "flex items-center h-[38px] w-full border border-gray-200 cursor-pointer px-2 rounded-lg", onClick: function () { return setIsExpanded(function (prev) { return !prev; }); } }, { children: [icon && (_jsx(AptiveIcon, { className: "w-[24px] h-[24px] stroke-[#9CA3AF] fill-none", icon: icon })), placeholder && (_jsx("div", __assign({ className: "ml-2 flex flex-col grow" }, { children: _jsx("input", { type: "text", placeholder: placeholder, className: "text-[#808080] text-sm leading-4 font-normal w-full outline-none border-transparent focus:border-transparent focus:ring-0 p-0", value: searchText, onChange: function (event) { return setSearchText(event.target.value); } }) })))] })), isExpanded && (_jsx("div", __assign({ ref: ref, className: "rounded-lg overflow-hidden absolute top-11 z-[100] bg-white w-full border border-gray-200" }, { children: _jsxs("div", __assign({ className: "overflow-auto", style: { maxHeight: maxVisibleOptions ? "".concat(32 + (maxVisibleOptions * 52), "px") : 'auto' } }, { children: [_jsxs("div", __assign({ className: "item flex items-center justify-between h-[32px] px-3 cursor-pointer hover:bg-gray-100", onClick: handleClickSelectAll }, { children: [_jsx("div", __assign({ className: "text-gray-600 text-[14px]" }, { children: "Select all" })), _jsx("div", { children: isAllOptionsSelected ? (_jsx(Checked, {})) : (_jsx(Unchecked, {})) })] })), filteredOptions.map(function (option) { return (_jsxs("div", __assign({ className: "item flex items-center justify-between h-[52px] border-t border-t-gray-200 px-3 cursor-pointer hover:bg-gray-100", "data-value": option.value, "data-label": option.label, "data-image": option.image, onClick: function () { return handleClickOption(option); } }, { children: [_jsxs("div", __assign({ className: "flex items-center gap-3" }, { children: [renderImage
                                            ? renderImage(option)
                                            : option.image
                                                ? option.image
                                                : null, _jsx("div", __assign({ className: "text-gray-600 text-[14px]" }, { children: option.label }))] })), isOptionSelected(option) ? (_jsx(Checked, {})) : (_jsx(Unchecked, {}))] }), option.value)); })] })) })))] })));
};
export default MultiSelect;
