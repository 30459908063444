export const salesChannelsConstants = {
  SALES_CHANNELS: 'Sales Channels',

  ID: 'id',
  ID_LABEL: 'Id',

  NAME: 'name',
  NAME_LABEL: 'Name',

  CREATION_DATE: 'created_at',
  CREATION_DATE_LABEL: 'Creation Date',

  SEARCH_SALES_CHANNELS: 'Search Sales Channels',

  STATUS: 'status',
  STATUS_LABEL: 'Status',

  EDIT: 'Edit',
  DELETE: 'Delete',

  REMOVE_CONFIRM: 'Are you sure?',
};
