import * as plans from './plans';
import * as settings from './settings';
import * as apiClients from './api-clients';
import * as planUpgradePaths from './plan-upgrade-paths';
import * as areaPlans from './area-plans';
import * as repDetails from './rep-details';
import * as groupPlans from './group-plans';
import * as customGroups from './custom-groups';
import * as salesChannels from './sales-channels';

const combinedApis = {
  ...plans,
  ...settings,
  ...apiClients,
  ...planUpgradePaths,
  ...areaPlans,
  ...repDetails,
  ...groupPlans,
  ...customGroups,
  ...salesChannels,
};

export default combinedApis;
