import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Controller, useFormContext } from 'react-hook-form';
import { CustomFormElement, FormLabel } from '@/components/common';
import { ChildRadioGroupWrapper } from '../../common';
import { payDetailsRadioConfig, onboardingConstants } from '@/lib';
import { formatCurrencyStringToNumber, formatNumberToCurrencyString } from '@/lib/utils';
import CurrencyInput from '@/components/common/Form/CurrencyInput';
import { onboardingDataValues } from '@/lib/constants/onboarding-data-values';
import PayTable from './PayTable/PayTable';
import { defaultSelectOption } from '@/lib/configs';
import { salesRanksSelector } from '@/redux/onboarding';
import { connect } from 'react-redux';

const {
  HAS_CV_BASED_PAY,
  SALES_RANK,
  UPFRONT_LABEL,
  UPFRONT_PAY_NAME,
  RENT_DEDUCTION_NAME,
  UPFRONT_PAY_LABEL,
  RENT_DEDUCTION_LABEL,
  UNIFORM_DEDUCTION_NAME,
  UNIFORM_DEDUCTION_LABEL,
  RENT_DEDUCTION_REASON_NAME,
  RENT_DEDUCTION_REASON_LABEL,
  SALES_RANK_LABEL,
} = onboardingConstants;

const PayDetails = ({
  canEditField,
  onChangeHandler,
  onBlurTrimSpace,
  showRentNote,
  salesRanks,
}) => {
  const { control, getValues, register, formState: { errors } } = useFormContext();

  const { hasCvBasedPay } = getValues();

  const upfrontPayDetails = [
    {
      label: RENT_DEDUCTION_LABEL,
      name: RENT_DEDUCTION_NAME,
      mask: {
        allowDecimal: true,
        decimalSymbol: '.',
        decimalLimit: 2,
        integerLimit: 7,
      },
    },
    {
      label: UNIFORM_DEDUCTION_LABEL,
      name: UNIFORM_DEDUCTION_NAME,
    },
  ];

  const cvPayDetails = [
    {
      label: UPFRONT_PAY_LABEL,
      name: UPFRONT_PAY_NAME,
    },
    ...upfrontPayDetails,
  ];

  const salesRanksOptions = useMemo(
    () => ([
      ...defaultSelectOption,
      ...salesRanks.map((salesRank) => ({ name: salesRank.name, value: salesRank.sales_rank_id })),
    ]),
    [salesRanks],
  );

  return (
    <div>
      <div className="mb-6">
        <CustomFormElement
          label={SALES_RANK_LABEL}
          type="select"
          id={SALES_RANK}
          name={SALES_RANK}
          register={register}
          selectOptions={salesRanksOptions}
          onChange={onChangeHandler}
          error={errors[SALES_RANK]}
          required
          disabled={!canEditField(SALES_RANK)}
        />
      </div>

      <CustomFormElement
        label={UPFRONT_LABEL}
        type="radio"
        id={HAS_CV_BASED_PAY}
        name={HAS_CV_BASED_PAY}
        register={register}
        radioOptions={payDetailsRadioConfig}
        onChange={onChangeHandler}
        orientation="horizontal"
        required
        disabled={!canEditField(HAS_CV_BASED_PAY)}
        isOpen={hasCvBasedPay !== onboardingDataValues.NULL_VALUE}
      />
      {hasCvBasedPay !== onboardingDataValues.NULL_VALUE && (
        hasCvBasedPay == onboardingDataValues.TRUE_VALUE ?
          (
            <ChildRadioGroupWrapper>
              <div className="flex flex-col w-full">
                <PayTable />
                <div className="flex flex-row w-full px-3 py-4 space-x-5 bg-gray-50">
                  {upfrontPayDetails.map(({ label, name, mask }) => (
                    <div key={name} className="flex-1 mt-1sm:col-span-2">
                      <FormLabel label={label} htmlFor={name} />
                      <Controller
                        name={name}
                        control={control}
                        render={({ field: { value } }) => (
                          <CurrencyInput
                            id={name}
                            name={name}
                            value={value}
                            mask={mask}
                            onChange={onChangeHandler}
                            onBlur={(event) => {
                              onChangeHandler({
                                target: {
                                  name,
                                  value: formatNumberToCurrencyString(
                                    formatCurrencyStringToNumber(event.target.value),
                                    mask?.decimalLimit ?? 0,
                                  ),
                                },
                                type: event.type,
                              });
                            }}
                            disabled={!canEditField(name)}
                          />
                        )}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </ChildRadioGroupWrapper>
          )
          :
          (
            <ChildRadioGroupWrapper>
              <div className="flex flex-row w-full px-3 py-4 space-x-5 bg-gray-50">
                {cvPayDetails.map(({ label, name, mask }) => (
                  <div key={name} className="flex-1 mt-1sm:col-span-2">
                    <FormLabel label={label} htmlFor={name} />
                    <Controller
                      name={name}
                      control={control}
                      render={({ field: { value } }) => (
                        <CurrencyInput
                          id={name}
                          name={name}
                          value={value}
                          mask={mask}
                          onChange={onChangeHandler}
                          onBlur={(event) => {
                            onChangeHandler({
                              target: {
                                name,
                                value: formatNumberToCurrencyString(
                                  formatCurrencyStringToNumber(event.target.value),
                                  mask?.decimalLimit ?? 0,
                                ),
                              },
                              type: event.type,
                            });
                          }}
                          disabled={!canEditField(name)}
                        />
                      )}
                    />
                  </div>
                ))}
              </div>
            </ChildRadioGroupWrapper>
          )
      )}

      {showRentNote && (
        <div className="sm:col-span-4 row-start-2 row-end-3">
          <CustomFormElement
            id={RENT_DEDUCTION_REASON_NAME}
            name={RENT_DEDUCTION_REASON_NAME}
            label={RENT_DEDUCTION_REASON_LABEL}
            type="textArea"
            rows={3}
            onChange={onChangeHandler}
            onBlur={(event) => onBlurTrimSpace(event, RENT_DEDUCTION_REASON_NAME)}
            register={register}
            error={errors[RENT_DEDUCTION_REASON_NAME]}
            required
            disabled={!canEditField(RENT_DEDUCTION_REASON_NAME)}
          />
        </div>
      )}
    </div>
  );
};

PayDetails.propTypes = {
  canEditField: PropTypes.func,
  onChangeHandler: PropTypes.func,
  onBlurTrimSpace: PropTypes.func,
  showRentNote: PropTypes.bool,
  salesRanks: PropTypes.array,
};

const mapStateToProps = (state) => ({
  salesRanks: salesRanksSelector(state),
});

export default connect(mapStateToProps)(PayDetails);
