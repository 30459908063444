import { startOfWeek, endOfWeek, startOfYear, endOfYear, addWeeks } from 'date-fns';
export function getWeeksOfYear(year) {
    var weeks = [];
    var startWeekDate = startOfWeek(startOfYear(new Date(year, 0, 1)), { weekStartsOn: 0 }); // Starting from the first Monday
    var weekCount = 1;
    while (startWeekDate.getFullYear() <= year) {
        var endWeekDate = endOfWeek(startWeekDate, { weekStartsOn: 0 });
        weeks.push({
            week: weekCount,
            start: startWeekDate.getFullYear() < year ? startOfYear(new Date(year, 0, 1)) : startWeekDate,
            end: endWeekDate.getFullYear() > year ? endOfYear(new Date(year, 11, 31)) : endWeekDate,
        });
        startWeekDate = addWeeks(startWeekDate, 1);
        weekCount++;
    }
    return weeks;
}
