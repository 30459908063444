import { useEffect, useRef } from 'react';
import { useFieldArray } from 'react-hook-form';
import { CustomFormElement } from '@/components/common';
import AddButton from '@/modules/AdminTools/components/AddButton';
import { planBuilderConstants } from '@/modules/AdminTools/lib/constants/plan-builder';

const {
  PLAN_UI_ELEMENTS,
  UI_ELEMENTS_OPTIONS,
  UI_ELEMENTS_LABEL,
  UI_ELEMENTS_NAME,
} = planBuilderConstants;

const Options = ({ control, register, index }) => {
  const { fields: options, append, remove } = useFieldArray({
    control,
    name: `${PLAN_UI_ELEMENTS}.${index}.data.options`,
  });

  const initialOptionsAppended = useRef(false);

  useEffect(() => {
    if (!initialOptionsAppended.current && options.length === 0) {
      append({ label: 'Option 1', name: 'option1' });
      append({ label: 'Option 2', name: 'option2' });
      initialOptionsAppended.current = true;
    }
  }, [append, options]);

  return (
    <div className="mt-4">
      <h5 className="text-gray-700">Options</h5>
      {options.map((option, optIndex) => (
        <div key={option.id} className="flex items-center space-x-2 mt-2">
          <CustomFormElement
            type="text"
            name={`${PLAN_UI_ELEMENTS}.${index}.data.${UI_ELEMENTS_OPTIONS}.${optIndex}.${UI_ELEMENTS_LABEL}`}
            className="border p-2 rounded w-full"
            register={register}
            label="Option Label"
            required
          />
          <CustomFormElement
            type="text"
            name={`${PLAN_UI_ELEMENTS}.${index}.data.${UI_ELEMENTS_OPTIONS}.${optIndex}.${UI_ELEMENTS_NAME}`}
            className="border p-2 rounded w-full"
            register={register}
            label="Option Name"
            required
          />
          {optIndex > 1 && (
            <button
              type="button"
              className="text-red-500"
              onClick={() => remove(optIndex)}
            >
              Remove
            </button>
          )}
        </div>
      ))}
      <AddButton onClick={() => append({ label: '', name: '' })} shouldShow>
        Add Option
      </AddButton>
    </div>
  );
};

export default Options;
