import { useLocation } from 'react-router-dom';

import { SideNav as BaseSideNav } from '@/components';
import { adminToolPages } from '@/modules/AdminTools/routing';
import { PATH as promoCodePath } from '@/modules/AdminTools/routing/promo-code';
import { NAME as salesSettingsByAreaName } from '@/modules/AdminTools/routing/sales-settings-by-area';
import { NAME as targetContractValueName } from '@/modules/AdminTools/routing/target-contract-value';
import { useFeatureFlag } from 'configcat-react';
import { dashboardConstants } from '@/lib/constants';
import { useCallback, useMemo } from 'react';

const {
  PROMOTION_BUILDER_FEATURE_FLAG_NAME,
  SALES_SETTINGS_BY_AREA_PAGE_ENABLED,
} = dashboardConstants;

const SideNav = () => {
  const location = useLocation();

  const isCurrentLocation = useCallback((path) => {
    path = path.replace(/^\/|\/$/g, '');
    const pathParts = location.pathname.replace(/^\/|\/$/g, '').split('/');

    return pathParts[0] === path;
  }, [location]);

  const { value: isPromotionBuilderEnabled } = useFeatureFlag(
    PROMOTION_BUILDER_FEATURE_FLAG_NAME,
    false,
  );

  const { value: isSalesSettingsByAreaPageEnabled } = useFeatureFlag(
    SALES_SETTINGS_BY_AREA_PAGE_ENABLED,
    false,
  );

  const items = useMemo(
    () => adminToolPages.map(({ NAME, PATH }) => ({
      name: NAME,
      path: PATH,
      active: isCurrentLocation(PATH),
    })).filter(
      ({ name }) => isSalesSettingsByAreaPageEnabled
        ? name !== targetContractValueName
        : name !== salesSettingsByAreaName,
    ).filter(({ path }) => isPromotionBuilderEnabled || path !== promoCodePath),
    [isCurrentLocation, isPromotionBuilderEnabled, isSalesSettingsByAreaPageEnabled],
  );

  return <BaseSideNav items={items} />;
};

export default SideNav;
