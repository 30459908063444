import React, { useState } from 'react';
import { DndContext, closestCenter, DragOverlay } from '@dnd-kit/core';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { useFormContext, useFieldArray } from 'react-hook-form';
import Labeled from '@/modules/AdminTools/components/Labeled';
import { DraggableItem, DroppableArea, DraggableOverlay, SortableItem } from './UIElements';
import { planBuilderConstants } from '@/modules/AdminTools/lib/constants/plan-builder';

const { PLAN_UI_ELEMENTS, AVAILABLE_UI_FIELDS } = planBuilderConstants;

const UIElementConfigurator = ({ UIElementsHeader }) => {
  const { register, control } = useFormContext();
  const { fields, append, remove, move } = useFieldArray({ control, name: PLAN_UI_ELEMENTS });
  const [activeField, setActiveField] = useState(null);
  const [isDraggingFromSidebar, setIsDraggingFromSidebar] = useState(false);
  const [newlyAddedFieldId, setNewlyAddedFieldId] = useState(null);

  const handleDragStart = (event) => {
    const { active } = event;
    const field = AVAILABLE_UI_FIELDS[active.id];
    setActiveField(field);
    setIsDraggingFromSidebar(!!field);
  };

  const handleDragEnd = (event) => {
    const { active, over } = event;
    setActiveField(null);

    if (newlyAddedFieldId) {
      setNewlyAddedFieldId(null);
      return;
    }

    if (isDraggingFromSidebar) {
      setIsDraggingFromSidebar(false);
      return;
    }

    if (over && active.id !== over.id) {
      move(
        fields.findIndex((item) => item.field_id === active.id),
        fields.findIndex((item) => item.field_id === over.id)
      );
    }
  };

  const handleDragOver = (event) => {
    const { active, over } = event;

    if (!AVAILABLE_UI_FIELDS[active.id]) {
      return;
    }

    if (!newlyAddedFieldId) {
      handleAddField(active.id);
      
      return;
    }

    if (!over) {
      return;
    }

    if (fields.some((f) => f.field_id === over.id) && newlyAddedFieldId !== over.id) {
      move(
        fields.findIndex((item) => item.field_id === newlyAddedFieldId),
        fields.findIndex((item) => item.field_id === over.id)
      );
    }
  };

  const handleAddField = (field_type) => {
    const newField = {
      field_id: Date.now(),
      type: field_type,
      sort_order: fields.length,
      data: {},
      name: '',
      label: '',
      is_active: true,
    };
    setNewlyAddedFieldId(newField.field_id);
    append(newField);
  };

  const handleDeleteField = (id) => {
    remove(fields.findIndex((field) => field.field_id === id));
  };

  return (
    <Labeled label={<UIElementsHeader />}>
      <DndContext
        collisionDetection={closestCenter}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
        onDragOver={handleDragOver}
      >
        <div className="flex flex-row space-x-4">
          <div className="w-1/3">
            <ul>
              {Object.entries(AVAILABLE_UI_FIELDS).map(([type, label]) => (
                <DraggableItem key={type} field={{ type, label }} isDraggingFromSidebar={isDraggingFromSidebar} />
              ))}
            </ul>
          </div>
          <DroppableArea hasFields={fields.length > 0}>
            <SortableContext items={fields.map(field => field.field_id)} strategy={verticalListSortingStrategy}>
              {fields.map((field, index) => (
                <SortableItem
                  key={field.field_id}
                  id={field.field_id}
                  field={field}
                  register={register}
                  isDraggingSelected={field.field_id === newlyAddedFieldId}
                  onDelete={handleDeleteField}
                  index={index}
                />
              ))}
            </SortableContext>
          </DroppableArea>
        </div>
        <DragOverlay>
          {activeField ? <DraggableOverlay field={activeField} /> : null}
        </DragOverlay>
      </DndContext>
    </Labeled>
  );
};

export default UIElementConfigurator;
