import {
  SalesChannels,
  SalesChannelsManager,
} from '@/modules/AdminTools/modules/SalesChannels';

import { abilityConstants } from '@/lib';

const { ACCESS_PLAN_BUILDER_ABILITY } = abilityConstants;

export const PATH = '/sales-channels';

export const NAME = 'Sales Channels';

export const routes = (protectedRoute, layout) => ({
  path: PATH,
  element: layout,
  children: [
    {
      element: protectedRoute(<SalesChannelsManager />, ACCESS_PLAN_BUILDER_ABILITY),
      children: [
        {
          index: true,
          element: <SalesChannels />,
        },
      ],
    },
  ],
});
