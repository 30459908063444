var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useMemo, useEffect } from 'react';
import { AptiveIcon, Button } from '@aptive-env/storybook';
import { getOptimizationStatus, startRouteOptimization } from 'services/routeOptimization';
import { format, isToday, isTomorrow } from 'date-fns';
import { useToast } from 'hooks/useToast';
import { getErrorMessage } from 'utils/error';
var POLLING_INTERVAL = 3000;
export var PlanningFooterSection = function (_a) {
    var _b, _c;
    var officeId = _a.officeId;
    var setToast = useToast().setToast;
    var _d = useState(false), isRunning = _d[0], setIsRunning = _d[1];
    var _e = useState(), errorMessage = _e[0], setErrorMessage = _e[1];
    var _f = useState(), status = _f[0], setStatus = _f[1];
    var handleClickRunRouteOptimization = function () { return __awaiter(void 0, void 0, void 0, function () {
        var error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!officeId) return [3 /*break*/, 4];
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    setIsRunning(true);
                    return [4 /*yield*/, startRouteOptimization({
                            officeId: officeId,
                            date: new Date(),
                        })
                        // Workaround: Delay the first status fetch to give the backend more time to store its job status;
                        // otherwise, the frontend may incorrectly assume the optimization is complete.
                    ];
                case 2:
                    _a.sent();
                    // Workaround: Delay the first status fetch to give the backend more time to store its job status;
                    // otherwise, the frontend may incorrectly assume the optimization is complete.
                    setTimeout(function () {
                        fetchStatus();
                    }, 5000);
                    return [3 /*break*/, 4];
                case 3:
                    error_1 = _a.sent();
                    setToast({
                        status: 'danger',
                        message: getErrorMessage(error_1),
                    });
                    console.error('Error initiating optimization:', error_1);
                    setIsRunning(false);
                    setErrorMessage('Optimization failed. Please try again.');
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var fetchStatus = function () { return __awaiter(void 0, void 0, void 0, function () {
        var response, error_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!officeId) return [3 /*break*/, 4];
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, getOptimizationStatus({ officeId: officeId, date: new Date() })];
                case 2:
                    response = _a.sent();
                    setStatus(response.result);
                    if (response.result.current) {
                        setTimeout(fetchStatus, POLLING_INTERVAL);
                    }
                    else {
                        setIsRunning(false);
                    }
                    return [3 /*break*/, 4];
                case 3:
                    error_2 = _a.sent();
                    setToast({
                        status: 'danger',
                        message: getErrorMessage(error_2),
                    });
                    console.error('Error fetching optimization status:', error_2);
                    setIsRunning(false);
                    setErrorMessage('Optimization failed. Please try again.');
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var formatDateTime = function (dateTimeStr) {
        var date = new Date(dateTimeStr);
        if (isToday(date)) {
            return "today at ".concat(format(date, 'p'), ".");
        }
        if (isTomorrow(date)) {
            return "tomorrow at ".concat(format(date, 'p'), ".");
        }
        return format(date, 'EEEE, MMMM d, yyyy, p') + '.';
    };
    var optimizationButton = useMemo(function () {
        if (isRunning) {
            return (_jsx("button", __assign({ type: "button", className: "flex items-center justify-center w-[198px] h-[38px] bg-[#007AFF] cursor-not-allowed text-white font-bold rounded-lg", disabled: true }, { children: _jsx("span", __assign({ className: "inline-flex items-center px-2.5" }, { children: _jsx("span", { className: "animate-spin h-[18px] w-[18px] rounded-full border-b-[2.62px] border-l-[2.62px] border-white" }) })) })));
        }
        return (_jsxs("div", __assign({ className: "flex gap-4 items-center" }, { children: [errorMessage && (_jsxs("div", __assign({ className: "flex gap-2 items-center" }, { children: [_jsx(AptiveIcon, { className: "w-6 h-6 stroke-red-600 fill-none", icon: "exclamation" }), _jsx("span", __assign({ className: "font-inter text-xs text-red-600 font-medium" }, { children: errorMessage }))] }))), _jsx(Button, __assign({ size: "default", variant: "default", disabled: !officeId, onClick: handleClickRunRouteOptimization }, { children: "Run Route Optimization" }))] })));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [errorMessage, isRunning]);
    useEffect(function () {
        if (officeId) {
            fetchStatus();
        }
        return function () {
            setIsRunning(false);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [officeId]);
    return (_jsxs("div", __assign({ className: "flex-none flex gap-2 w-full h-[84px] border border-l-0 border-r-0 border-b-0 border-t-1 border-gray-200 items-center px-6" }, { children: [_jsxs("div", __assign({ className: "flex items-start gap-2" }, { children: [_jsx(AptiveIcon, { className: "w-[24px] h-[24px] stroke-[#4B5563] fill-none", icon: "clock" }), _jsxs("div", { children: [_jsx("div", __assign({ className: "text-xs text-gray-700 leading-4" }, { children: _jsxs("span", __assign({ className: "font-medium" }, { children: ["Last optimized ", _jsx("span", __assign({ className: "font-bold" }, { children: ((_b = status === null || status === void 0 ? void 0 : status.previous) === null || _b === void 0 ? void 0 : _b.timestamp) ? formatDateTime(status.previous.timestamp) : 'N/A' }))] })) })), _jsx("div", __assign({ className: "text-xs text-gray-700 leading-4" }, { children: _jsxs("span", __assign({ className: "font-medium" }, { children: ["Next optimization scheduled for ", _jsx("span", __assign({ className: "font-bold" }, { children: ((_c = status === null || status === void 0 ? void 0 : status.next) === null || _c === void 0 ? void 0 : _c.timestamp) ? formatDateTime(status.next.timestamp) : 'N/A' }))] })) }))] })] })), _jsx("div", __assign({ className: "ml-auto" }, { children: optimizationButton }))] })));
};
