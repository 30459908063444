export const onboardingDataValues = {
  MARRIED_VALUE: 'Married',
  SINGLE_VALUE: 'Single',
  YES_VALUE: 'yes',
  NO_VALUE: 'no',
  ZERO_VALUE: '0',
  ONE_VALUE: '1',
  TRUE_VALUE: 'true',
  FALSE_VALUE: 'false',
  NULL_VALUE: 'null',
  PASSPORT_VALUE: 'passport',
  DL_AND_SOCIAL_VALUE: 'driverLicenseAndSocial',
  SELECT_VALUE: '-- Select --',
  MALE_VALUE: 'Male',
  FEMALE_VALUE: 'Female',
  OTHER_VALUE: 'Other',
  NONE_VALUE: 'None',
  JAN_VALUE: 'Jan',
  FEB_VALUE: 'Feb',
  MAR_VALUE: 'Mar',
  APR_VALUE: 'Apr',
  MAY_VALUE: 'May',
  JUN_VALUE: 'Jun',
  JUL_VALUE: 'Jul',
  AUG_VALUE: 'Aug',
  SEP_VALUE: 'Sep',
  OCT_VALUE: 'Oct',
  NOV_VALUE: 'Nov',
  DEC_VALUE: 'Dec',
  BEIGE_VALUE: 'Beige',
  BLACK_VALUE: 'Black',
  BLUE_VALUE: 'Blue',
  BROWN_VALUE: 'Brown',
  GOLD_VALUE: 'Gold',
  GRAY_VALUE: 'Gray',
  GREEN_VALUE: 'Green',
  ORANGE_VALUE: 'Orange',
  PURPLE_VALUE: 'Purple',
  RED_VALUE: 'Red',
  SILVER_VALUE: 'Silver',
  WHITE_VALUE: 'White',
  YELLOW_VALUE: 'Yellow',
  BLONDE_VALUE: 'Blonde',
  HAZEL_VALUE: 'Hazel',
  XS_VALUE: 'XS',
  S_VALUE: 'S',
  M_VALUE: 'M',
  L_VALUE: 'L',
  XL_VALUE: 'XL',
  XXL_VALUE: 'XXL',
  XXXL_VALUE: 'XXXL',
  XXXXL_VALUE: 'XXXXL',
  S_M_VALUE: 'S/M',
  L_XL_VALUE: 'L/XL',
  ASIAN_VALUE: 'Asian',
  BLACK_OR_AFRICAN_VALUE: 'Black or African American',
  NATIVE_AMERICAN_VALUE: 'Native American or Alaskan Native',
  NATIVE_HAWAIIAN_VALUE: 'Native Hawaiian or Other Pacific Islander',
  HISPANIC_OR_LATINO_VALUE: 'Hispanic or Latino',
  TWO_OR_MORE_RACES_VALUE: 'Two or more races',
  PREFER_NOT_TO_DISCLOSE_VALUE: 'Prefer not to disclose',
  COUNTRY_STATE_OTHER_NAME: 'Other',
  STATE_OTHER_VALUE: 'OTHER',
  LAST_INDUSTRY_OTHER_VALUE: 'other',
  COUNTRY_OTHER_VALUE: '0',
  ACCOUNT_TYPE_CHECKING_VALUE: 'Checking',
  ACCOUNT_TYPE_SAVINGS_VALUE: 'Savings',
  W9_INDIVIDUAL_VALUE: 'Individual/Sole Proprietor or single-member LLC',
  W9_C_CORPORATION_VALUE: 'C Corporation',
  W9_S_CORPORATION_VALUE: 'S Corporation',
  W9_PARTNERSHIP_VALUE: 'Partnership',
  W9_LLC_C_CORPORATION_VALUE: 'C',
  W9_LLC_S_CORPORATION_VALUE: 'S',
  W9_LLC_PARTNERSHIP_VALUE: 'P',
  W9_TRUST_ESTATE_VALUE: 'Trust/estate',
  W9_LLC_VALUE: 'Limited liability company',
  W9_OTHER_VALUE: 'Other',
  I9_CITIZEN_VALUE: '1',
  I9_NONCITIZEN_US_NATIONAL_VALUE: '2',
  I9_PERMANENT_RESIDENT_VALUE: '3',
  I9_NONCITIZEN_VALUE: '4',
  CV_BASED_PAY_VALUE: 'CV-based pay',
  UPFRONT_BASED_PAY_VALUE: 'Upfront pay',
};
