var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect } from 'react';
import { Navbar, Navbars, AptiveIcon } from '@aptive-env/storybook';
import DetailHeader from 'pages/SalesRepresentatives/ViewDetail/DetailHeader';
import DetailPersonalInfo from 'pages/SalesRepresentatives/ViewDetail/DetailPersonalInfo';
import DetailLicensing from 'pages/SalesRepresentatives/ViewDetail/DetailLicensing';
import DetailNotes from 'pages/SalesRepresentatives/ViewDetail/DetailNotes';
import LicenseModal from 'pages/SalesRepresentatives/License';
import DeleteLicenseModal from 'pages/SalesRepresentatives/DeleteLicense';
import { fetchProfile, fetchStates, fetchLicense, fetchLicenseAttachments, } from 'services';
import '../../index.scss';
var ProfileView = function (_a) {
    var className = _a.className;
    var _b = useState(0), activeTab = _b[0], setActiveTab = _b[1];
    var _c = useState(null), profile = _c[0], setProfile = _c[1];
    var _d = useState([]), states = _d[0], setStates = _d[1];
    var _e = useState(''), search = _e[0], setSearch = _e[1];
    var _f = useState([]), licenses = _f[0], setLicenses = _f[1];
    var _g = useState(false), isLicenseModalOpen = _g[0], setIsLicenseModalOpen = _g[1];
    var _h = useState(false), isDeleteLicenseModalOpen = _h[0], setIsDeleteLicenseModalOpen = _h[1];
    var _j = useState(null), selectedLicense = _j[0], setSelectedLicense = _j[1];
    var _k = useState([]), selectedLicenseAttachments = _k[0], setSelectedLicenseAttachments = _k[1];
    var onLicenseAdd = function () {
        setSelectedLicense(null);
        setIsLicenseModalOpen(true);
    };
    var onLicenseEdit = function (license) { return __awaiter(void 0, void 0, void 0, function () {
        var licensesResponse, uploadedAttachment;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, fetchLicense(license.license_id)];
                case 1:
                    licensesResponse = _a.sent();
                    return [4 /*yield*/, fetchLicenseAttachments(license.license_id)];
                case 2:
                    uploadedAttachment = _a.sent();
                    setSelectedLicense(licensesResponse.result.license);
                    setSelectedLicenseAttachments(uploadedAttachment.result.attachments);
                    setIsLicenseModalOpen(true);
                    return [2 /*return*/];
            }
        });
    }); };
    var onLicenseDelete = function (license) {
        setSelectedLicense(license);
        setIsDeleteLicenseModalOpen(true);
    };
    var fetchInitialValues = function () { return __awaiter(void 0, void 0, void 0, function () {
        var _a, profileResponse, statesResponse;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, Promise.all([
                        fetchProfile(),
                        fetchStates(),
                    ])];
                case 1:
                    _a = _b.sent(), profileResponse = _a[0], statesResponse = _a[1];
                    setProfile(profileResponse.result.rep);
                    setStates(statesResponse.result.states);
                    setLicenses(profileResponse.result.licenses);
                    return [2 /*return*/];
            }
        });
    }); };
    var handleHideShowLicense = function (licenseId, isHidden) {
        var tempLicenses = __spreadArray([], licenses, true);
        for (var _i = 0, tempLicenses_1 = tempLicenses; _i < tempLicenses_1.length; _i++) {
            var license = tempLicenses_1[_i];
            if (license.license_id === licenseId) {
                license.hidden = isHidden;
            }
        }
        setLicenses(tempLicenses);
    };
    useEffect(function () {
        fetchInitialValues();
    }, []);
    return (_jsxs("div", __assign({ className: "relative flex flex-col grow h-0 ".concat(className !== null && className !== void 0 ? className : '') }, { children: [_jsxs("div", __assign({ className: "absolute left-0 top-0 right-0 bottom-0 flex flex-col bg-white" }, { children: [_jsx("div", __assign({ className: "border-b border-gray-200 px-6 py-5" }, { children: _jsxs("a", __assign({ href: "/", className: "flex items-center gap-4 cursor-pointer w-fit" }, { children: [_jsx(AptiveIcon, { className: "w-[24px] h-[24px] stroke-[#9CA3AF] fill-none", icon: "arrowCircleLeft" }), _jsx("span", __assign({ className: "font-inter text-sm text-gray-600" }, { children: "Back" }))] })) })), _jsxs("div", __assign({ className: "flex flex-col grow overflow-hidden" }, { children: [_jsx("div", __assign({ className: "flex justify-center px-6 pt-6 pb-2 bg-gray-100 border-b border-gray-200" }, { children: _jsx(DetailHeader, { profile: profile, className: "w-[660px] pr-0" }) })), _jsx("div", __assign({ className: "flex justify-center bg-white border-b border-gray-200" }, { children: _jsxs(Navbars, __assign({ className: 'gap-4 w-[660px] border-none', defaultValue: 0 }, { children: [_jsx(Navbar, { className: 'px-0 py-[23px]', title: 'Personal Info', onClick: function () { return setActiveTab(0); } }), _jsx(Navbar, { className: 'px-0 py-[23px]', title: 'Licensing', onClick: function () { return setActiveTab(1); } }), _jsx(Navbar, { className: 'px-0 py-[23px]', title: 'Notes', onClick: function () { return setActiveTab(2); } })] })) })), _jsx("div", __assign({ className: "flex justify-center bg-gray-50 p-6 grow overflow-hidden" }, { children: _jsxs("div", __assign({ className: "bg-white rounded-lg w-[660px] overflow-auto no-scrollbar" }, { children: [activeTab === 0 && _jsx(DetailPersonalInfo, {}), activeTab === 1 &&
                                            _jsx(DetailLicensing, { licenses: licenses, states: states, search: search, onSearch: setSearch, onAdd: onLicenseAdd, onEdit: onLicenseEdit, onDelete: onLicenseDelete }), activeTab === 2 && _jsx(DetailNotes, {})] })) }))] }))] })), isLicenseModalOpen && (_jsx(LicenseModal, { isOpen: isLicenseModalOpen, onClose: function () { return setIsLicenseModalOpen(false); }, license: selectedLicense, salesRepresentative: profile, setSalesRepresentativeLicenses: setLicenses, selectedLicenseAttachments: selectedLicenseAttachments, states: states })), _jsx(DeleteLicenseModal, { isOpen: isDeleteLicenseModalOpen, onClose: function () { return setIsDeleteLicenseModalOpen(false); }, onHideShow: handleHideShowLicense, license: selectedLicense, salesRepresentative: profile })] })));
};
export default ProfileView;
