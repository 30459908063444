import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ExclamationIcon } from '@heroicons/react/outline';
import { CustomButton } from '@/components';
import ModalWrapper from '@/components/modals/ModalWrapper';

const NotLicensedRepsModal = ({
  handleAssign,
  restrictedRepsLicenseInfo,
  setRestrictedRepsLicenseInfo,
}) => {
  const repsCanBeAssigned = restrictedRepsLicenseInfo.filter(rep => !rep.is_license_hard_forced);
  const repsCantBeAssigned = restrictedRepsLicenseInfo.filter(rep => rep.is_license_hard_forced);

  const onClick = () => {
    handleAssign(true);
    setRestrictedRepsLicenseInfo([]);
  };

  const onClose = () => {
    setRestrictedRepsLicenseInfo([]);
  };

  const renderModalContent = () => {
    return (
      <div className="text-sm text-gray-600 mb-4">
        {repsCanBeAssigned.length > 0 && (
          <>
            <p>Reps that can be assigned:</p>
            <ul className="mt-4 mb-2 pl-4 list-disc">
              {repsCanBeAssigned.map((repLicenseInfo) => (
                <li key={repLicenseInfo.rep_id} className="gap-x-2 text-gray-900 pb-2">
                  <p>{`${repLicenseInfo.rep_full_name} (ID #${repLicenseInfo.rep_id})`}</p>
                </li>
              ))}
            </ul>
          </>
        )}
        {repsCantBeAssigned.length > 0 && (
          <>
            <p>Reps that cannot be assigned due to licensing requirements:</p>
            <ul className="mt-4 mb-2 pl-4 list-disc">
              {repsCantBeAssigned.map((repLicenseInfo) => (
                <li key={repLicenseInfo.rep_id} className="gap-x-2 text-gray-900 pb-2">
                  <p>{`${repLicenseInfo.rep_full_name} (ID #${repLicenseInfo.rep_id})`}</p>
                </li>
              ))}
            </ul>
            <p className="text-red-500">*Please reach out to licensing administrator for details</p>
          </>
        )}
      </div>
    );
  };

  return (
    <ModalWrapper
      isOpened={restrictedRepsLicenseInfo.length > 0}
      onCloseModal={onClose}
      width="max-w-[512px] w-full"
    >
      <div className="p-6 flex flex-nowrap items-start justify-between gap-x-4">
        <div className="w-10">
          <div className="w-10 h-10 flex items-center justify-center bg-aptivered-lightest rounded-full">
            <ExclamationIcon className="h-6 w-6 stroke-aptivered" aria-hidden="true" />
          </div>
        </div>
        <div className="grow">
          <h1 className="mb-2 text-lg font-medium text-gray-900">
            Unable to assign one or more reps to polygon
          </h1>
          {renderModalContent()}
          <div className="flex items-center justify-end">
            <CustomButton
              color="green"
              className="px-6 py-2 font-normal"
              onClick={!repsCanBeAssigned.length ? onClose : onClick}
            >
              {!repsCanBeAssigned.length ? 'Close' : 'Acknowledge & assign'}
            </CustomButton>
          </div>
        </div>
      </div>
    </ModalWrapper>
  );
};

const mapDispatchToProps = {};

NotLicensedRepsModal.propTypes = {
  restrictedRepsLicenseInfo: PropTypes.arrayOf(PropTypes.object),
  setRestrictedRepsLicenseInfo: PropTypes.func,
};

export default connect(null, mapDispatchToProps)(NotLicensedRepsModal);
