export const planBuilderConstants = {
  PLAN_BUILDER: 'Plan Builder',

  PLANS: 'Plans',

  ID: 'id',

  NAME: 'name',
  NAME_LABEL: 'Plan name',

  EXT_REF: 'ext_reference_id',
  EXT_REF_LABEL: 'External Reference Id',

  START_ON: 'start_on',
  START_ON_LABEL: 'Start date',

  END_ON: 'end_on',
  END_ON_LABEL: 'End date',

  SEARCH_PLANS: 'Search Plans',

  PLAN_STATUS: 'plan_status_id',
  PLAN_STATUS_LABEL: 'Status',

  PLAN_CATEGORIES: 'plan_category_ids',
  PLAN_CATEGORIES_LABEL: 'Categories',

  PLAN_SALES_CHANNELS: 'sales_channel_ids',
  PLAN_SALES_CHANNELS_LABEL: 'Sales Channels',

  PRODUCT: 'product_id',

  AGREEMENT_LENGTH: 'agreement_length_ids',
  AGREEMENT_LENGTH_LABEL: 'Length of service',

  PLAN_SERVICE_FREQUENCY: 'plan_service_frequency_id',
  PLAN_SERVICE_FREQUENCY_LABEL: 'Annual services for contract fulfillment',

  REMOVE_CONFIRM: 'Are you sure?',

  SERVICES: 'services',
  SERVICES_LABEL: 'Services',

  ADDONS: 'Addons',

  ADDITIONAL_SERVICES_LABEL: 'Additional Services',

  PLAN_PRICING_LEVELS: 'Pricing Levels',
  PLAN_PRICING_LEVEL: 'plan_pricing_level_id',

  PERCENTAGE_THRESHOLD: 'can_sell_percentage_threshold',
  PERCENTAGE_THRESHOLD_LABEL: 'Percentage Threshold',

  PRICING_LEVEL_LABEL: 'Pricing Level',

  ORDER: 'order',
  ORDER_LABEL: 'Order',

  EDIT: 'Edit',
  DELETE: 'Delete',

  IS_INCLUDED: 'is_included',

  AREA_LABEL: 'Area',
  AREA: 'area_id',

  VALUE_LABEL: 'Value',
  VALUE: 'value',

  TARGET_ID_VALUE: 'target_id',
  TARGET_TYPE_VALUE: 'target_type',

  AREA_TARGET_TYPE_VALUE: 'area',
  REP_TARGET_TYPE_VALUE: 'rep',

  TARGET_CONTRACT_VALUE_LABEL: 'Target Contract Value',
  TARGET_CONTRACT_VALUE: 'target_contract_value',

  TWENTY_FOUR_MONTH_THRESHOLD_LABEL: '24-Month Threshold Percentage',
  TWENTY_FOUR_MONTH_THRESHOLD_VALUE: 'threshold_24_month_percentage',

  INITIAL_DISCOUNT_LABEL: 'Initial Floor Discount for reps',
  INITIAL_DISCOUNT: 'initial_discount',

  RECURRING_DISCOUNT_LABEL: 'Treatment Floor Discount for reps',
  RECURRING_DISCOUNT: 'recurring_discount',

  MONTHLY_DISCOUNT_LABEL: 'Monthly Discount for reps',
  MONTHLY_DISCOUNT: 'monthly_discount',

  API_CLIENTS: 'Plan Builder API Clients',

  PLAN_UI_ELEMENTS: 'plan_ui_elements',
  UI_ELEMENTS_NAME: 'name',
  UI_ELEMENTS_LABEL: 'label',
  UI_ELEMENTS_OPTIONS: 'options',
  UI_ELEMENTS_IS_ACTIVE: 'is_active',
  UI_ELEMENTS_IS_REQUIRED: 'is_required',
  UI_ELEMENTS_DESCRIPTION: 'description',

  UI_ELEMENTS_FIELD_LABEL: 'Field Label',
  UI_ELEMENTS_FIELD_NAME_LABEL: 'Field Name',
  UI_ELEMENTS_IS_ACTIVE_LABEL: 'Is Active',
  UI_ELEMENTS_IS_REQUIRED_LABEL: 'Is Required',
  UI_ELEMENTS_DESCRIPTION_LABEL: 'Description',

  PLAN_UI_ELEMENTS_FEATURE_FLAG_NAME: 'isPlanUiElementsEnabled',

  AVAILABLE_UI_FIELDS: {
    dropdown: 'Dropdown',
    file: 'File Upload',
  },
};
