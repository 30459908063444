import { Table, ConfirmationModal } from '@/components';
import { SearchBar } from '@/components/common';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useEffect, useState } from 'react';
import { useFeatureFlag } from 'configcat-react';

import {
  AddFilterButton,
  FilterDropdownButton,
  Button,
} from '@/modules/Housing/components/common';
import {
  getLedgerHeadRows,
  parseLedgerRows,
} from '@/modules/Housing/modules/Ledger/lib';
import { ledgerConstants } from '@/modules/Housing/lib';
import { payStatus } from '@/modules/Housing/lib/constants';
import {
  setSelectedLedgerAction,
  archiveLedgerAsync,
  unArchiveLedgerAsync,
  cancelLedgerAsync,
  updateOvernightPaymentAsync,
} from '@/modules/Housing/redux/ledger';
import { useDraggable } from '@/modules/Housing/hooks';
import { requestUsersAsync } from '@/redux/users';

const {
  LEDGER_SEARCH_NAME,
  CANCEL_LEDGER_FEATURE_FLAG_NAME,
} = ledgerConstants;

const LedgersTable = ({
  // Own Props
  initialPage,
  pageSize,
  setPageSize,
  selectedPage,
  onPageChange,
  ledgers,
  ledgersTotal,
  filters,
  setFilters,
  searchValue,
  setSearchValue,
  branches,
  teams,
  dealers,
  partnerships,
  typeOfPayments,
  complexes,
  paymentMethods,
  isLoading,
  archiveLedger,
  unArchiveLedger,
  cancelLedger,
  archived,
  setIsModalOpen,
  setSelectedLedger,
  refreshLedgers,
  updateOvernightPayment,
  getUsers,
}) => {
  const [filterValueOptions, setFilterValueOptions] = useState([]);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [cancelOpen, setCancelOpen] = useState(false);
  const { tableRef } = useDraggable(isLoading);
  const [clickedLedgerId, setClickedLedgerId] = useState(null);
  const { value: isCancellingLedgerLinesEnabled } = useFeatureFlag(
    CANCEL_LEDGER_FEATURE_FLAG_NAME,
    false,
  );
  const [mdHolders, setMdHolders] = useState([]);

  const requestMdHolderUsers = (page, search, successCallback, errorCallback) => {
    const requestParams = {
      page: {
        number: page,
        size: 50,
      },
      filter: {
        md_holder: 1,
      },
    };

    if (search) {
      requestParams.filter = {
        ...requestParams.filter,
        search: search,
      };
    }

    return getUsers({
      ...requestParams,
      successCallback: successCallback ? successCallback : (users) => {
        const data = users?.data?.map((user) => ({
          value: +user?.id,
          name: user?.attributes?.name,
        })) ?? [];
        setMdHolders(data);

        return users;
      },
      errorCallback: errorCallback ? errorCallback : () => {},
    });
  };

  useEffect(() => {
    requestMdHolderUsers(1);
  }, []);

  const onConfirmationCancel = () => {
    setConfirmationOpen(false);
  };

  const onConfirmationOk = () => {
    setConfirmationOpen(false);
    doArchiveLedger();
  };

  const onClickArchive = (ledgerId) => {
    setConfirmationOpen(true);
    setClickedLedgerId(ledgerId);
  };

  const onClickCancel = (ledgerId) => {
    setCancelOpen(true);
    setClickedLedgerId(ledgerId);
  };

  const onCancelSubmit = () => {
    setCancelOpen(false);

    let cancelledStatus;
    payStatus.forEach((thisStatus) => {
      if (thisStatus.name === 'Cancelled') {
        cancelledStatus = thisStatus.value;
      };
    });

    cancelLedger({
      ledgerId: clickedLedgerId,
      pay_status_id: cancelledStatus,
      successCallback: () => refreshLedgers(),
    });
  };

  const onCancel = () => {
    setCancelOpen(false);
  };

  const doArchiveLedger = () => {
    const action = archived ? unArchiveLedger : archiveLedger;

    action({
      ledgerId: clickedLedgerId,
      successCallback: () => refreshLedgers(),
    });
  };

  const onClickUpdateOvernightPayment = (ledgerId, value) => {
    updateOvernightPayment({
      ledgerId: ledgerId,
      isOvernightPaymentRequest: value,
    });
  };

  const ledgerRows = parseLedgerRows(
    ledgers ?? [],
    archived,
    onClickArchive,
    onClickUpdateOvernightPayment,
    onClickCancel,
    isCancellingLedgerLinesEnabled,
  );

  const handleSearchClick = ({ searchText }) => {
    setSearchValue(searchText);
  };

  const filterTypeOptions = [
    {
      onClick: () => setFilterValueOptions(branches),
      label: 'Branch',
      value: 'branch_ids',
      type: 'dropdown',
    },
    {
      onClick: () => {
        setFilterValueOptions(
          teams.map((team) => ({ name: team.label, value: team.value })),
        );
      },
      label: 'Team',
      value: 'team_ids',
      type: 'dropdown',
    },
    {
      onClick: () => setFilterValueOptions(dealers),
      label: 'Dealer',
      value: 'dealer_ids',
      type: 'dropdown',
    },
    {
      onClick: () => setFilterValueOptions(partnerships),
      label: 'Partnership',
      value: 'partnership_ids',
      type: 'dropdown',
    },
    {
      onClick: () => setFilterValueOptions(mdHolders),
      label: 'MD Holder',
      value: 'md_user_ids',
      type: 'dropdownInfinite',
      fetchMoreOptions: requestMdHolderUsers,
    },
    {
      onClick: () => setFilterValueOptions(typeOfPayments),
      label: 'Type of Payment',
      value: 'payment_type_ids',
      type: 'dropdown',
    },
    {
      onClick: () => setFilterValueOptions(complexes),
      label: 'Complex',
      value: 'complex_ids',
      type: 'dropdown',
    },
    {
      onClick: () => setFilterValueOptions(paymentMethods),
      label: 'Payment Method',
      value: 'payment_method_ids',
      type: 'dropdown',
    },
    {
      onClick: () => setFilterValueOptions(payStatus),
      label: 'Paid Status',
      value: 'pay_status_ids',
      type: 'dropdown',
    },
    {
      onClick: () => { },
      label: 'Date Due',
      value: 'date_due',
      type: 'daterange',
    },
    {
      onClick: () => { },
      label: 'Date Paid',
      value: 'date_paid',
      type: 'daterange',
    },
  ];

  return (
    <>
      {!isLoading && (
        <div className="mt-4 border border-solid border-gray-300 rounded-t-lg p-2 border-b-0 bg-white self-stretch flex items-center justify-between">
          <SearchBar
            searchText={searchValue}
            inputName={LEDGER_SEARCH_NAME}
            onSearchClick={handleSearchClick}
          />
          <div className="mr-4 flex items-center gap-6">
            {filters.map((filter, index) => {
              let options, type, fetchMoreOptions;
              switch (filter.type.value) {
                case 'branch_ids':
                  options = branches;
                  type = 'dropdown';
                  break;
                case 'team_ids':
                  options = teams.map((team) => ({ name: team.label, value: team.value }));
                  type = 'dropdown';
                  break;
                case 'dealer_ids':
                  options = dealers;
                  type = 'dropdown';
                  break;
                case 'partnership_ids':
                  options = partnerships;
                  type = 'dropdown';
                  break;
                case 'md_user_ids':
                  options = mdHolders;
                  type = 'dropdownInfinite';
                  fetchMoreOptions = requestMdHolderUsers;
                  break;
                case 'payment_type_ids':
                  options = typeOfPayments;
                  type = 'dropdown';
                  break;
                case 'complex_ids':
                  options = complexes;
                  type = 'dropdown';
                  break;
                case 'payment_method_ids':
                  options = paymentMethods;
                  type = 'dropdown';
                  break;
                case 'pay_status_ids':
                  options = payStatus;
                  type = 'dropdown';
                  break;
                case 'date_paid':
                  type = 'daterange';
                  break;
                case 'date_due':
                  type = 'daterange';
                  break;
                default:
                  break;
              }

              return (
                <FilterDropdownButton
                  filterValueOptions={options}
                  filters={filters}
                  setFilters={setFilters}
                  key={index}
                  index={index}
                  type={type}
                  fetchMoreOptions={fetchMoreOptions}
                />
              );
            })}

            <AddFilterButton
              labelClassName="text-gray-600 text-right font-normal sm:text-xs leading-none border-none shadow-none"
              buttonClassName="items-center"
              iconClassName="w-0 h-0"
              filterTypeOptions={filterTypeOptions}
              filterValueOptions={filterValueOptions}
              setFilters={setFilters}
              filters={filters}
              label={'+ Add Filters'}
            />
            <div className="flex items-center">
              <Button
                color={'default'}
                onClick={() => setFilters([])}
                className="text-gray-600 text-right font-normal sm:text-xs leading-none border-none shadow-none"
              >
                Clear Filters
              </Button>
            </div>
          </div>
        </div>
      )}
      <Table
        ref={tableRef}
        loading={isLoading}
        className="select-none"
        thead={{ rows: getLedgerHeadRows() }}
        tbody={{
          rows: ledgerRows,
          data: ledgers,
          classNames: 'hover:cursor-pointer',
          onSelect: (rowData) => {
            if (!rowData) {
              return;
            }
            setIsModalOpen(true);
            setSelectedLedger(rowData);
          },
        }}
        paginator={{
          pageSize,
          setPageSize,
          onPageChange,
          selectedPage,
          initialPage,
          rowCount: ledgersTotal,
        }}
        wrapper={{
          className: 'overflow-y-visible rounded-t-none',
        }}
      />
      <ConfirmationModal
        isOpened={confirmationOpen}
        modalWidth="max-w-[592px] w-full"
        onCancel={onConfirmationCancel}
        onAction={onConfirmationOk}
        title={`Are you sure you want to ${archived ? 'unarchive' : 'archive'} this ledger?`}
        message={
          archived ? 'By unarchiving this ledger, you will see it in the list of ledgers.'
            : 'By archiving this ledger, you will no longer see it in the list of ledgers.'}
        cancelLabel={'Cancel'}
        confirmLabel={`${archived ? 'Unarchive' : 'Archive'}`}
        confirmButtonColor="aptiveblue"
      />

      <ConfirmationModal
        isOpened={cancelOpen}
        modalWidth="max-w-[592px] w-full"
        onCancel={onCancel}
        onAction={onCancelSubmit}
        title={'Are you sure you want to cancel this ledger?'}
        message={'Please confirm you want to cancel this ledger line, this action cannot be undone.'}
        cancelLabel={'Cancel'}
        confirmLabel={'Submit'}
        confirmButtonColor="aptiveblue"
      />
    </>
  );
};

LedgersTable.propTypes = {
  initialPage: PropTypes.number,
  pageSize: PropTypes.number,
  setPageSize: PropTypes.func,
  selectedPage: PropTypes.number,
  onPageChange: PropTypes.func,
  ledgers: PropTypes.array,
  ledgersTotal: PropTypes.number,
  filters: PropTypes.array,
  setFilters: PropTypes.func,
  searchValue: PropTypes.string,
  branches: PropTypes.array,
  teams: PropTypes.array,
  dealers: PropTypes.array,
  partnerships: PropTypes.array,
  typeOfPayments: PropTypes.array,
  complexes: PropTypes.array,
  paymentMethods: PropTypes.array,
  isLoading: PropTypes.bool,
  archiveLedger: PropTypes.func,
  unArchiveLedger: PropTypes.func,
  cancelLedger: PropTypes.func,
  archived: PropTypes.bool,
  setIsModalOpen: PropTypes.func,
  setSelectedLedger: PropTypes.func,
  refreshLedgers: PropTypes.func,
  setSearchValue: PropTypes.func,
  updateOvernightPayment: PropTypes.func,
  getUsers: PropTypes.func,
};

const mapDispatchToProps = {
  setSelectedLedger: setSelectedLedgerAction,
  archiveLedger: archiveLedgerAsync.request,
  unArchiveLedger: unArchiveLedgerAsync.request,
  cancelLedger: cancelLedgerAsync.request,
  updateOvernightPayment: updateOvernightPaymentAsync.request,
  getUsers: requestUsersAsync.request,
};

export default connect(null, mapDispatchToProps)(LedgersTable);
