import React, { useEffect } from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import Dropdown from './fields/Dropdown';
import FileUpload from './fields/FileUpload';
import { TrashIcon, DotsHorizontalIcon } from '@heroicons/react/solid';

const SortableItem = ({ id, field, onAttributeChange, isDraggingSelected, onDelete, index }) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging: sortableIsDragging,
  } = useSortable({ id });
  const isDragging = isDraggingSelected || sortableIsDragging;
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0.5 : 1,
  };

  const renderField = () => {
    switch (field.type) {
      case 'dropdown':
        return <Dropdown field={field} onAttributeChange={onAttributeChange} index={index} />;
      case 'file':
        return <FileUpload field={field} onAttributeChange={onAttributeChange} index={index} />;
      default:
        return null;
    }
  };

  return (
    <div ref={setNodeRef} style={style} className="relative mb-4 p-2 border rounded bg-white shadow">
      <div className="flex justify-center p-1 cursor-grab" {...listeners} {...attributes}>
        <DotsHorizontalIcon className="h-4 w-4 text-gray-500" />
      </div>
      <button onClick={() => { onDelete(id); }} className="absolute top-2 right-2 p-1">
        <TrashIcon className="h-4 w-4 text-red-500" />
      </button>
      <div>
        {renderField()}
      </div>
    </div>
  );
};

export default SortableItem;
