// TODO: SPT:REMOVE

import TeamRepPanel from './TeamRepPanel';
import PropTypes from 'prop-types';
import { Loader } from '@/components';

const TeamReps = ({ loading, repList, selectedReps, onRepClick, onGlobalRepClick, draggedRef }) => {

  const handleOnRepClick = (selectedRepId) => {
    const isDragging = draggedRef.current;
    draggedRef.current = false;

    if (!isDragging) {
      onRepClick(selectedRepId);
    }
  }

  const handleOnGlobalRepClick = () => {
    const isDragging = draggedRef.current;
    draggedRef.current = false;

    if (!isDragging) {
      onGlobalRepClick();
    }
  }

  return (
    <div>
      <div className="flex items-center justify-between h-[41px] rounded-t-lg border-x border-b p-2.5 pt-3">
        <div className="text-sm pl-1.5">
          Reps <span className="text-gray-600 text-xs">{selectedReps.length > 0 ? `${selectedReps.length} selected` : ''}</span>
        </div>
        {repList && repList.length > 0 && (
          <div className="text-[0.8rem] pr-1.5">
            <div onClick={handleOnGlobalRepClick} onTouchStart={handleOnGlobalRepClick} className="text-blue-500 hover:cursor-pointer">
              {selectedReps.length === 0 ? 'Select all' : 'Deselect all'}
            </div>
          </div>
        )}
      </div>
      <div className="w-[280px] max-h-[574px] rounded-b-lg overflow-auto">
        {loading ?
          <Loader className="my-3.5" />
          :
          repList && repList.length > 0
            ? repList.map((rep) => {
              return (
                <TeamRepPanel
                  key={rep.user_id}
                  rep={rep}
                  selectedReps={selectedReps}
                  onRepClick={handleOnRepClick}
                />
              );
            })
            : (
              <div className="border-x p-1">
                <div className="px-3 py-2">
                  <div className="flex items-center justify-between">
                    <div className="text-sm">
                      There are no reps in the team.
                    </div>
                  </div>
                </div>
              </div>
            )
        }
      </div>
    </div>
  );
};

TeamReps.propTypes = {
  loading: PropTypes.bool,
  repList: PropTypes.array,
  selectedReps: PropTypes.array,
  onRepClick: PropTypes.func,
  onGlobalRepClick: PropTypes.func,
};

export default TeamReps;
